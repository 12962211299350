import React from 'react'
import Slides from './Testimonials/Slides'
import 'swiper/swiper-bundle.css';

class Testimonials extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        count: 0,
        testimonials: [

              {
                text: "I am flying today at 8am to Barbados, I have been stranded since March and I really needed it to enter my country, thank you for your great service",
                author: "Fauzi - Patient",
                image: "images/testimonial-3.svg"
              },
              {
                text: "Agradezco y valoro mucho su ayuda en mi caso especial",
                author: "Joaquin - Patient",
                image: "images/testimonial-3.svg"
              },
              {
                text: "Thank you very much for all your help and hard work!!",
                author: "Karen - Patient",
                image: "images/testimonial-2.svg"
              },
              {
                text: "Servicio de lujo!",
                author: "Regina - Patient",
                image: "images/testimonial-2.svg"
              },
              {
                text: "Dios los bendiga y devuelva con creces su buena voluntad, amabilidad y ayuda. Hacen falta personas como Uds en este mundo loco",
                author: "Sr Negrete - Patient",
                image: "images/testimonial-3.svg"
              },
              {
                text: "We recommending your company to a couple of my business associates. They are awesome",
                author: "Nicole - Business Patient",
                image: "images/testimonial-2.svg"
              },
              {
                text: "My family arrived in Barbados today after 10 months apart. This would have not been possible without you. A big thank you from a happy family, for whom you have made the difference!!!",
                author: "Massimiliano - Patient",
                image: "images/testimonial-3.svg"
              },
          ]
      };
    }

  increaseOne = () => {
    if (this.state.count !== this.state.testimonials.length-1) {
      this.setState({ count: this.state.count + 1  } )
    }
    else {
      this.setState({ count: 0 } )
    }
  }

  decreaseOne = () => {
    if (this.state.count !== 0) {
      this.setState({ count: this.state.count - 1  } )
    } else {
      this.setState({ count: this.state.testimonials.length-1  } )
    }
  }

  interval = setInterval(() => {
    this.increaseOne()
}, 10000);

  render(){
    return (
            <div className="slider-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="image-container">
                                <img className="img-fluid" src="images/testimonials-2-men-talking.svg" alt="alternative"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2>Testimonials</h2>
                            <div className="slider-container">
                                <div className="swiper-container card-slider">
                                    <div className="swiper-wrapper">
                                        <Slides slidenumber={this.state.count} testimonials= {this.state.testimonials} />
                                    </div>
                                        <div className="swiper-button-next" onClick={this.increaseOne}></div>
                                    <div className="swiper-button-prev" onClick={this.decreaseOne}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
  }
}
export default Testimonials;
