import React from 'react'

class Header extends React.Component{
  render(){
    return (
      <div>
        <header id="header" className="ex-header">
          <div className="header-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                      <img className="img-fluid center" src="images/checkIcon.png" alt="alternative"/>
                      <h2><span className="turquoise"></span>Thank You!!!</h2>
                    </div>
                </div>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
export default Header
