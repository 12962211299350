import React from 'react'
import NavBar from '../Components/Common/NavBar'
import Header from '../Components/TermsConditions/Header'
import Terms from '../Components/TermsConditions/Terms'

class TermsConditions extends React.Component{
  render(){
    return (
      <div>
      <NavBar current="Terms Conditions" />
      <Header />
      <Terms />
      </div>
    )
  }
}
export default TermsConditions;
