import React, { Component } from "react";

export default class ContactUs extends Component {
    Reset() {
        document.getElementById("zsWebToCase_528447000000698017").reset();
    }
    render() {
        return (
            <div>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            " #zohoSupportWebToCase textarea, #zohoSupportWebToCase input[type='text'], #zohoSupportWebToCase select, .wb_common{width: 280px;}#zohoSupportWebToCase td {padding: 11px 5px;}#zohoSupportWebToCase textarea, #zohoSupportWebToCase input[type='text'], #zohoSupportWebToCase select{border: 1px solid #ddd;padding: 3px 5px;border-radius: 3px;}#zohoSupportWebToCase select {box-sizing: unset}#zohoSupportWebToCase .wb_selectDate {width: auto}#zohoSupportWebToCase input.wb_cusInput {width: 108px}.wb_FtCon{display: flex;align-items: center;justify-content: flex-end;margin-top: 15px;padding-left: 10px}.wb_logoCon{display: flex;margin-left: 5px}.wb_logo{max-width: 16px;max-height: 16px;}.zsFormClass{background-color:#FFFFFF;width:600px} ",
                    }}
                />
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            ".zsFontClass{color:#000000;font-family:Arial;font-size:13px}",
                    }}
                />
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            ".manfieldbdr{border-left: 1px solid #ff6448!important} .hleft{text-align:left;} input[type=file]::-webkit-file-upload-button{cursor:pointer;}.wtcsepcode{margin:0px 15px; color:#aaa; float:left;}.wtccloudattach{float:left; color:#00a3fe!important; cursor:pointer; text-decoration: none!important;}.wtccloudattach:hover{text-decoration: none!important;}.wtcuploadinput{cursor:pointer; float:left; width:62px; margin-top:-20px; opacity:0; clear:both;}.wtcuploadfile{float:left;color: #00a3fe;}.filenamecls{margin-right:15px; float:left; margin-top:5px;} .clboth{clear:both;} #zsFileBrowseAttachments{clear:both; margin:5px 0px 10px;} .zsFontClass{vertical-align:top;} #tooltip-zc{font: normal 12px Arial, Helvetica, sans-serif; line-height:18px;position:absolute;padding:8px;margin:20px 0 0;background:#fff;border:1px solid #528dd1;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;color:#eee;-webkit-box-shadow:5px 5px 20px rgba(0,0,0,0.2);-moz-box-shadow:5px 5px 20px rgba(0,0,0,0.2);z-index:10000; color:#777}.wtcmanfield{color:#f00;font-size:16px;position:relative;top:2px;left:1px;} #zsCloudAttachmentIframe{width: 100%;height: 100%;z-index: 99999!important;position: fixed;left: 0px;top:0px; border-style: none; display: none; background-color:#fff;} .wtchelpinfo{background-position: -246px -485px;width:15px;height:15px;display:inline-block;position: relative;top: 2px;background-image: url('https://css.zohostatic.com/support/3701289/images/zs-mpro.png');} .zsMaxSizeMessage{font-size:13px;}",
                    }}
                />

                <form
                    name="zsWebToCase_528447000000698017"
                    id="zsWebToCase_528447000000698017"
                    action="https://desk.zoho.com/support/WebToCase"
                    method="POST"
                    onSubmit="return zsValidateMandatoryFields()"
                    encType="multipart/form-data"
                    autoComplete="off"
                >
                    <input
                        type="hidden"
                        name="xnQsjsdp"
                        value="zG2S7gSwAvAw-lEYqg3rXQ$$"
                    />
                    <input
                        type="hidden"
                        name="xmIwtLD"
                        value="JNM-hpBFxzDV1nQQqyOwFxvzLgdQ0zzM"
                    />
                    <input type="hidden" name="xJdfEaS" value="" />{" "}
                    <input type="hidden" name="actionType" value="Q2FzZXM=" />
                    <input type="hidden" id="property(module)" value="Cases" />
                    <input
                        type="hidden"
                        id="dependent_field_values_Cases"
                        value="{'JSON_VALUES':{},'JSON_SELECT_VALUES':{},'JSON_MAP_DEP_LABELS':[]}"
                    />
                    <input
                        type="hidden"
                        name="returnURL"
                        value="https://www.venilabs.com/"
                    />
                    <div className="form-container">
                        <div className="inputBox w50">
                            <input
                                type="text"
                                maxLength="120"
                                name="First Name"
                                required
                                autoComplete="off"
                            />
                            <span>First Name</span>
                        </div>
                        <div className="inputBox w50">
                            <input
                                type="text"
                                maxLength="120"
                                name="Contact Name"
                                required
                                autoComplete="off"
                            />
                            <span>Last Name</span>
                        </div>
                        <div className="inputBox w50">
                            <input
                                type="text"
                                maxLength="120"
                                name="Email"
                                required
                                autoComplete="off"
                            />
                            <span>Email</span>
                        </div>
                        <div className="inputBox w50">
                            <input
                                type="text"
                                maxLength="120"
                                name="Phone"
                                required
                                autoComplete="off"
                            />
                            <span>Phone</span>
                        </div>
                        <div className="selectSection">
                            <div className="selectBox w50">
                                <select
                                    name="Classification"
                                    onChange="setDependent(this, false)"
                                    id="Classification"
                                    required
                                >
                                    <option value="">-None-</option>
                                    <option value="Question">Question</option>
                                    <option value="Problem">Problem</option>
                                    <option value="Cancelation">
                                        Cancelation
                                    </option>
                                    <option value="Reschedule">
                                        Reschedule
                                    </option>
                                    <option value="Waiting for results">
                                        Waiting for results
                                    </option>
                                    <option value="Others">Others</option>
                                </select>
                                <span>Type of Inquiry</span>
                            </div>
                            <div className="selectBox w50">
                                <select
                                    name="Location"
                                    onChange="setDependent(this, false)"
                                    id="CASECF1"
                                    required
                                >
                                    <option value="">-None-</option>
                                    <option value="Broward County FL">
                                        Broward County FL
                                    </option>
                                    <option value="Houston TX">
                                        Houston TX
                                    </option>
                                    <option value="Los Angeles CA">
                                        Los Angeles CA
                                    </option>
                                    <option value="Miami-Dade County FL">
                                        Miami-Dade County FL
                                    </option>
                                    <option value="Madison WI">
                                        Madison WI
                                    </option>
                                    <option value="New York City Area NY">
                                        New York City Area NY
                                    </option>
                                    <option value="Palm Beach FL">
                                        Palm Beach FL
                                    </option>
                                    <option value="Richmond VA">
                                        Richmond VA
                                    </option>
                                    <option value="San Francisco CA">
                                        San Francisco CA
                                    </option>
                                    <option value="Tampa FL">Tampa FL</option>
                                </select>
                                <span>Service Area</span>
                            </div>

                            <div className="selectBox w50">
                                <select
                                    name="Language"
                                    onChange="setDependent(this, false)"
                                    id="Language"
                                    required
                                >
                                    <option value="">-None-</option>
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                </select>
                                <span>Language</span>
                            </div>
                        </div>
                        <div className="inputBox w50">
                            <input
                                type="text"
                                maxLength="255"
                                name="Subject"
                                required
                                autoComplete="off"
                            />
                            <span>Subject</span>
                        </div>

                        <div className="inputBox w100">
                            <textarea
                                name="Description"
                                maxLength="3000"
                                required
                            ></textarea>
                            <span>Message</span>
                        </div>
                        <div className="inputBox w100">
                            <input
                                type="submit"
                                id="zsSubmitButton_528447000000698017"
                                class="zsFontClass"
                                value="Submit"
                            />
                        </div>

                        <div className="inputBox w100">
                            <input
                                type="reset"
                                id="reset1"
                                class="zsFontClass"
                                value="Reset"
                                onClick={this.Reset}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
