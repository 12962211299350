import React from 'react'
import PricingCard from './Pricing/Card'
import PricingWideCard from './Pricing/WideCard'

const pricingCardData =  [
  
    { cardTitle: "RT-PCR",
    cardSubtitle: "Find out if you have SARS-CoV-2",
    cardValue: "139",
    cardBullets: [ "Results in 2-4 days via email",
                   "Detects SARS-CoV-2 presence (Includes delta and omicron variants)",
                   "Nasal or Oral swab",
                   "In-home or office services",
                   "FDA Approved",
                   "CLIA-Certified laboratory"
                 ],
    cardLink: "Checkout"
  },
  {
    cardTitle: "Expedited PCR",
    cardSubtitle: "Especial for travellers",
    cardValue: "239",
    cardBullets: [ "Results in 1-3 days via email",
                   "Same as RT-PCR test",
                   "Includes $100 Expedited fee",
                   "Detects the delta and omicron variants"
                 ],
    cardLabel: "Travel",
    cardLink: "Checkout"
  },
  {
    cardTitle: "Same Day PCR",
    cardSubtitle: "Only available in South Florida",
    cardValue: "289",
    cardBullets: [ "Results the same day via email for samples taken before 12pm",
                   "Same as RT-PCR test",
                   "Includes $150 Same Day fee",
                   "Detects the delta and omicron variants"
                 ],
    cardLabel: "Same Day",
    cardLink: "Checkout",
  },
  { cardTitle: "Specimen Collection",
    cardSubtitle: "Find out if you have SARS-CoV-2",
    cardValue: "75",
    cardBullets: [ "Results in 2-3 days via email",
                   "Same as RT-PCR test",
                   "This service covers the In-home or office specimen collection",
                   "RT-PCR test will be billed to your insurance"
                 ],
    cardLabel: "Insurance only",
    // cardLink: "InsuranceCheckout",

    cardButton: "Coming soon"
  },
  { cardTitle: "Antigen Test",
    cardSubtitle: "Find out if you have SARS-CoV-2",
    cardValue: "79",
    cardBullets: [ "Verbal results in 15 minutes",
                   "Detects SARS-CoV-2 presence",
                   "Nasal swab",
                   "In-home or office services",
                   "FDA Approved under Emergency use Authorization (UEA)",
                   "Not suitable for travellers, due to verbal results only ",
                 ],
      cardLabel: "Rapid",
      cardLink: "Checkout"
  },
  {
    cardTitle: "Antibody",
    cardSubtitle: "Find out if you had the virus",
    cardValue: "99",
    cardBullets: [ "Detects past infections",
                   "Blood sample needed",
                   "In-home or office services",
                   "FDA Approved",
                   "CLIA-Certified laboratory"
                 ],
    cardLink: "Checkout"
  }
  // {
  //   cardTitle: "Respiratory Panel",
  //   cardSubtitle: "Comprehensive testing ",
  //   cardValue: "599",
  //   cardBullets: ["Detects for 29 known Corona and influenza virus strains",
  //                 "If you want to know for certain which virus is ailing you",
  //                 "In-Home Service",
  //                 "FDA Approved",
  //                 "CLIA-Certified laboratory"],
  //   // cardLabel: "New",
  //   hoverText: "Includes: Covid-19, Adenovirus, Bocavirus, Coronavirus 229E, Coronavirus HKU-1, Coronavirus NL63, Coronavirus OC43, Enterovirus, Influenza A, Influenza B, Metapneumovirus A & B, Parainfluenza virus Type 1, Parainfluenza virus Type 2, Parainfluenza virus Type 3, Parainfluenza virus Type 4, Parechovirus, Respiratory Syncytial virus A & B, Rhinovirus, Bordetella pertussis, Chlamydophila pneumoniae, Harmophilis influenzae, Klebsiella pneumoniae, Legionella, Moraxella catarrhalis, Mycoplasma pneumoniae, Salmonella spp, Staphylococcus pneumoniae",
  //   cardLink: "Checkout"
  // }
  // {
  //   cardTitle: "Saliva RT-PCR",
  //   cardSubtitle: "Find out if you have SARS-CoV-2",
  //   cardValue: "200",
  //   cardBullets: [ "Results in 48-72 Hours",
  //                  "Detects SARS-CoV-2 presence",
  //                  "No swab, Only Saliva",
  //                  "In-home or office services",
  //                  "FDA Approved",
  //                  "CLIA-Certified laboratory"
  //                ],
  //   cardLabel: "New"
  // }
]


class Pricing extends React.Component{
  render(){
    return (
            <div id="pricing" className="cards-2">
                <div className="container">
                    <hr/>
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Covid-19 Testing Options</h2>
                            <p className="p-heading p-large">All pricing are flat rates, pricing includes in-home or at your office visit. We're great for small companies and large organizations</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-4 ">
                          <PricingCard cardInfo={pricingCardData[0]} />
                        </div>
                        <div className="col-lg-4 ">
                          <PricingCard cardInfo={pricingCardData[1]}/>
                        </div>
                        <div className="col-lg-4">
                          <PricingCard cardInfo={pricingCardData[2]}/>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                          <PricingCard cardInfo={pricingCardData[3]}/>
                        </div>
                        <div className="col-lg-4">
                          <PricingWideCard cardInfo={pricingCardData[4]}/>
                        </div>
                        <div className="col-lg-4">
                          <PricingWideCard cardInfo={pricingCardData[5]}/>
                        </div>
                    </div>

                </div>
            </div>
          )
        }
      }
export default Pricing;
