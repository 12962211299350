import React from "react";

const locations = [
    { name: "Orange County - CA", phone: "+1 213-228-3883" },
    { name: "San Francisco - CA", phone: "+1 415-319-9089" },
    { name: "South Florida - FL", phone: "+1 954-906-9480" },
    { name: "Tampa - FL", phone: "+1 813-497-4046" },
    { name: "New York City - NY", phone: "+1 332-205-9112" },
    { name: "Houston - TX", phone: "+1 281-519-2510" },
    { name: "Richmond - VA", phone: "+1 804-373-2664" },
    { name: "Washington DC - Metro", phone: "+1 202-953-1520" }
];
class ServiceArea extends React.Component {
    render() {
        return (
            <div id="ServiceArea" className="form-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Service Area</h2>
                            <ul className="list-unstyled li-space-lg">
                                <li className="address">
                                    Don't hesitate to give us a call or send us
                                    a email
                                </li>
                                <div className="divServices">
                                    <div>
                                        <li>
                                            <i className="material-icons">
                                                local_phone
                                            </i>
                                            &nbsp;
                                            <a
                                                className="turquoise"
                                                href="tel:8555500358"
                                            >
                                                Toll Free Number +1 855-550-0358
                                            </a>
                                        </li>
                                    </div>
                                    <div>
                                        <li>
                                            <i className="material-icons">
                                                mail
                                            </i>
                                            &nbsp;
                                            <a
                                                className="turquoise"
                                                href="mailto:info@venilabs.com"
                                            >
                                                info@venilabs.com
                                            </a>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            {locations.map((location) => {
                                return (
                                    <div
                                        key={location["name"]}
                                        className="col-md-4"
                                    >
                                        <div className="card text-center m-3 shadow">
                                            <h5 className="card-title m-3">
                                                {location["name"]}
                                            </h5>
                                            {/* <br></br> */}
                                            <a
                                                className="card-text mb-3 turquoise"
                                                href={
                                                    "tel:" + location["phone"]
                                                }
                                            >
                                                {location["phone"]}
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ServiceArea;
