import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <div>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="footer-col">
                                    <h4>About Venilabs</h4>
                                    <p>
                                        We are a small company set out to help
                                        those that can't risk getting sick by
                                        leaving their homes. Those that are sick
                                        and don't want to continue spreading the
                                        virus. The small businesses that care
                                        for their employees' health and can't
                                        afford to be shut down.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-col middle">
                                    <h4>Important Links</h4>
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i class="material-icons">stop</i>
                                            &nbsp;To order a test click &nbsp;
                                            <Link
                                                to="/checkout"
                                                className="turquoise"
                                            >
                                                here
                                            </Link>
                                        </li>
                                        <li className="media">
                                            <i class="material-icons">stop</i>
                                            &nbsp; For Terms and conditions
                                            click &nbsp;
                                            <Link
                                                to="/termsconditions"
                                                className="turquoise"
                                            >
                                                here
                                            </Link>
                                        </li>
                                        <li className="media">
                                            <i class="material-icons">stop</i>
                                            &nbsp; For Privacy policy click
                                            &nbsp;
                                            <Link
                                                to="/privacypolicy"
                                                className="turquoise"
                                            >
                                                here
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
