import React from 'react'

class Header extends React.Component{
  render(){
    return (
      <div>
        <header id="header" className="ex-header">
          <div className="header-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
export default Header
