import React from 'react'
import NavBar from '../Components/Common/NavBar'
import Header from '../Components/PatientThankYou/Header'
import ThankYou from '../Components/PatientThankYou/ThankYou'

class PatientThankYou extends React.Component{
  render(){
    return (
      <div>
        <NavBar />
        <Header />
        <ThankYou />
      </div>
    )
  }
}
export default PatientThankYou;
