import React from "react";
import ContactUs from "./ContactUs";

class Contact extends React.Component {
    render() {
        return (
            <div id="contact">
                <section className="form-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h2>Contact us by filling the Form</h2>
                                    <p className="p-large">
                                        {" "}
                                        <strong>
                                            -- Before contacting us please read
                                            below --
                                        </strong>
                                    </p>
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body p-large">
                                                <strong className="blue">
                                                    To order your test:{" "}
                                                </strong>
                                                <a href="/Checkout">
                                                    CLICK HERE!
                                                </a>
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Appointment Confirmation:
                                                </strong>{" "}
                                                We will call you to confirm
                                                appointment up to 24hrs prior
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Tests Available:{" "}
                                                </strong>
                                                Covid-19 PCR and Antibody
                                                Analysis
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Cost:
                                                </strong>{" "}
                                                Covid-19 PCR -{" "}
                                                <strong className="red">
                                                    $150
                                                </strong>{" "}
                                                | Antibody Analysis -{" "}
                                                <strong className="red">
                                                    $100
                                                </strong>
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Travellers:
                                                </strong>{" "}
                                                If you are going to travel and
                                                need quicker results please
                                                select the Expedited PCR service
                                                for{" "}
                                                <strong className="red">
                                                    $300
                                                </strong>
                                                . Results will be provided
                                                within 24-48 Hours. So far we
                                                have helped people reach these
                                                destinations: China, Barbados,
                                                Bahamas, Puerto Rico, Jamaica,
                                                Continental USA, Europe,
                                                Guatemala, Salvador, Ecuador,
                                                Bolivia.
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Type of Swab:{" "}
                                                </strong>
                                                Oral is standard unless
                                                requested before appointment
                                                (Oral is less intrusive than
                                                nasal, and it is tested with the
                                                same method)
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Results Wait-time:
                                                </strong>{" "}
                                                Please allow a minimun of 48
                                                hours after collection. Please
                                                reach out if you have not
                                                received your results after 3-5
                                                days
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Results Via Email and/or
                                                    text:
                                                </strong>{" "}
                                                We will send your results via
                                                email or text as soon as its
                                                ready. Please check your SPAM
                                                folder for results before
                                                contacting us
                                            </div>
                                        </li>
                                        <li className="media p-large">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                <strong className="blue">
                                                    Scheduling:{" "}
                                                </strong>
                                                Our online form will allow you
                                                to schedule at your convenience.
                                                Our online calendar is always
                                                up-to-date
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="contactZohoForm col-lg-6">
                                <ContactUs />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Contact;
