import React from 'react'
import NavBar from '../Components/Common/NavBar'
import Header from '../Components/PrivacyPolicy/Header'
import Policy from '../Components/PrivacyPolicy/Policy'

class PrivacyPolicy extends React.Component{
  render(){
    return (
      <div>
      <NavBar />
      <Header />
      <Policy />
      </div>

    )
  }
}
export default PrivacyPolicy;
