import React from 'react'

class Policy extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="ex-basic-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="text-container">

                                                            <div className="WordSection1">
                                <p
                                    className="MsoNormal"
                                    align="center"
                                    style={{
                                    marginBottom: "12.0pt",
                                    textAlign: "center",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        background: "white"
                                        }}
                                    >
                                        Veni Labs
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoNormal"
                                    align="center"
                                    style={{
                                    marginBottom: "12.0pt",
                                    textAlign: "center",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Privacy Policy
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoNormal"
                                    align="center"
                                    style={{
                                    marginBottom: "12.0pt",
                                    textAlign: "center",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <i>
                                        <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                        >
                                        Last modified on August 11, 2020
                                        </span>
                                    </i>
                                    </b>
                                </p>
                                <p
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", color: "black", background: "white" }}>
                                    Veni Labs, Inc. “Company,” “us,” “we,” or “Veni Labs”) provides{" "}
                                    </span>
                                    <span style={{ fontSize: "10.0pt" }}>the Venilabs.com</span>
                                    <span style={{ fontSize: "10.0pt" }}>
                                    website and related web, mobile and other software programs and
                                    applications (
                                    </span>
                                    <span style={{ fontSize: "10.0pt" }}>
                                    collectively, the “Site”) and takes your privacy and security seriously
                                    </span>
                                    <span style={{ fontSize: "10.0pt" }}>
                                    . This Privacy Policy explains how we collect, use, share and protect
                                    personal data (“Personal Information”) we receive from users.
                                    </span>
                                </p>
                                <p
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt" }}>
                                    We collect Personal Information from users when they view, access, use or
                                    register through the Site or use any related services offered therein
                                    (collectively, “Services”). The provision of certain information is
                                    contractually required based on your use of the Services.{" "}
                                    </span>
                                </p>
                                <p
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt" }}>
                                    This Privacy Policy applies to persons and businesses anywhere in the
                                    world that may access the Site. By using the Site or our Services, you
                                    agree to be bound by this Privacy Policy in full. If you do not wish to
                                    provide Personal Information or be bound by this Privacy Policy, you may
                                    not use the Site or the Services.
                                    </span>
                                </p>
                                <p
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt" }}>
                                    The Company reserves the right to modify, change, or replace this Privacy
                                    Policy at any time by updating this document. You agree to be bound by the
                                    most recent version of the Privacy Policy available on the Site. The date
                                    of the last modification will be displayed near the top of the Privacy
                                    Policy.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        1.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        INFORMATION COLLECTED
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Our legal basis for collecting and processing your Personal Information is
                                    (i) your explicit consent when accessing the Services and (ii) a
                                    legitimate interest and need in order to provide specific Services offered
                                    on the Site, as described in our Terms of Service. We may collect the
                                    following Personal Information from users:
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Information You Provide Voluntarily.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;We regularly receive and store any information you enter on the Site
                                    or give us in any other way, whether it be through filling out a form, or
                                    through an actual communication. You may elect to not provide certain
                                    information, but such an election may prevent you from taking advantage
                                    of, and accessing many of our features. We use the information that you
                                    provide for purposes such as: responding to your communications and/or
                                    requests, customizing future experiences on the Site for you, improving
                                    our Site, and communicating with you in any other way. The types of
                                    personally identifiable information which may be collected through your
                                    use of the Site include, but are not limited to: your name, physical
                                    address, any email address, phone numbers, fax numbers, billing address,
                                    shipping address, test and product selections or views, order and/or
                                    confirmation numbers, financial account information, debit card numbers
                                    and expiration dates, credit card numbers and expiration dates, credit
                                    card account information, referring URLs, IP addresses, passwords,
                                    usernames, gender, occupation, personal interests, your age and
                                    information about your hobbies, likes, interests in and regular use of
                                    certain products, programs and other services you use or may be interested
                                    in. Additionally, we will receive certain information such as health
                                    information and test results collected and compiled as a result of the
                                    provision of the Services.&nbsp; You voluntarily provide the above
                                    personally identifiable information to us when you enter such information
                                    on the Site, whether input with the intention of facilitating a purchase,
                                    payment, newsletter subscription, promotion or contest and/or any other
                                    special offers or benefits.&nbsp; You are the source of this information.
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Information Provided Automatically.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;Upon your use or interaction with the Site, we automatically receive
                                    and store certain types of information. We obtain certain types of
                                    information when the browser you are using accesses the Site or
                                    advertisements therein, and other content served on the Site itself, or on
                                    behalf of the Site through other web sites.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Non-Personal Information:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;We collect non-personally-identifying information of the sort that
                                    web browsers and servers typically make available upon their interaction
                                    with Sites.&nbsp; This information may include such things as the browser
                                    type used during the interaction, the language preference, the referring
                                    website, and the date and time of each visitor request.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Your IP Address:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;Your Internet Protocol (“IP”) address is a unique identifying code
                                    which is used in connecting your computer to the Internet. Your IP Address
                                    may be used for various purposes, including to: (i) help us identify you,
                                    and your purchase orders, and to gather general and broad demographic
                                    information; (ii) help us diagnose or service technology problems that are
                                    associated with the IP addresses controlled by a specific Web company or
                                    ISP; (iii) help us estimate the total number of users visiting the Site
                                    located in certain geographic locations; and (iv) help us determine which
                                    users have access privileges or restrictions to certain content available
                                    on the Site.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Device Identifier:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;We may provide services that allow users to visit, view, access and
                                    customize the Site and other content through the use of their mobile
                                    devices such as:&nbsp; phones, PDAs and other similar electronic devices.
                                    When the Site is accessed through these devices rather than a computer,
                                    different information may be collected. When you visit or connect with the
                                    Site through the use of a mobile device that has Internet accessibility,
                                    in the event that the mobile device service provider uniquely identifies
                                    the device, we will be able to receive this identifying information. We
                                    may use this unique device information to be able to offer extended
                                    services and/or functionality. Certain services, offers you elect to
                                    enroll in, or purchases you wish to make through the Site may require the
                                    collection of the phone number for your device. We may associate and
                                    categorize the phone number collected along with the mobile device
                                    information.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Your Physical Location:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;Some mobile phone service providers in the United States are
                                    required to operate using a certain functionality which pinpoints the
                                    physical location of any mobile devices currently using their service.
                                    Depending upon which service provider your device is enrolled with, and if
                                    you are visiting the Site while you are located in the United States, we
                                    may automatically receive this information. We may use and store such
                                    information received in order to assist us in providing opportunities for
                                    services based on your location, as well as to assist with advertising
                                    which is targeted based on location.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Web Cookies:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;For advertising and other purposes as described below, we use a
                                    feature in your Internet Web browser called a “cookie,” which is a piece
                                    of information stored on your hard drive, that identifies your intra- and
                                    inter-requests on the Site. Cookies may be used for various purposes,
                                    including the tracking of your preferences which you indicate while
                                    visiting or using the Site. If you wish to disable the use of cookies,
                                    please review the Help section of your Internet browser, and review the
                                    instructions therein on how to turn off this feature. You can also
                                    indicate your choices regarding display advertising, through the use of
                                    specific platforms, which include but may not be limited to the Digital
                                    Advertising Alliance opt-out platform or the Network Advertising
                                    Initiative opt-out platform. To opt-out of these cookies, please visit the
                                    following URLs:
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.5in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <a href="http://www.aboutads.info/choices">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        http://www.aboutads.info/choices
                                    </span>
                                    </a>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    ;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.5in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <a href="http://www.allaboutcookies.org/">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        allaboutcookies.org
                                    </span>
                                    </a>
                                    <span className="MsoHyperlink">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        ;{" "}
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    or&nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.5in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <a href="http://www.youronlinechoices.eu/">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        youronlinechoices.eu
                                    </span>
                                    </a>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Beacons:
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;Web pages included on the Site may contain e-images called a “Web
                                    beacon,” also known as a “single-pixel GIF,” which allow a Site to count
                                    the number of users who have visited that particular page or to access
                                    specific cookies and the information contained therein. We may use such
                                    Web beacons for the following purposes:&nbsp; (a) to count and determine
                                    the number of users and to recognize such users through the accessing of
                                    Site cookies, (b) to count, determine, and recognize the number of users
                                    who have opened or read emails sent to the user by us, and (c) to assist
                                    in personalizing your interaction with the Site to make the experience
                                    more efficient and enjoyable for you.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        2.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        SHARING AND USE OF PERSONAL INFORMATION
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "7.5pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    In some instances, we may disclose certain information about you to
                                    third-parties whose practices are not governed or covered by this Privacy
                                    Policy. Due to the current and existing regulations, we are unable to
                                    specifically ensure that any or all of your personal information and
                                    private communications will not ever be disclosed in ways not covered or
                                    defined within the terms herein. One example of such activity may include
                                    the ability of third-parties to unlawfully intercept, access, or capture
                                    certain transmissions of information or private communications. If you
                                    wish for us to not disclose any personal information collected about you
                                    in any of the ways identified and described within this Privacy Policy,
                                    you should refrain from visiting or accessing the Site or any related
                                    services.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "7.5pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        News; Offers.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;In some instances, we may provide certain personal information which
                                    you input while registering or signing up to receive news, offers,
                                    updates, sweepstakes or contests, to trusted, third-party partners who
                                    work directly with us. Such third-parties may use that personal
                                    information to communicate with you about certain updates, offers or
                                    surveys from us and/or such third-parties. If you wish to opt out of
                                    receiving such offers and/or materials, please email your desired request
                                    to opt out to: infor@venilabs.com
                                    .
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{ marginLeft: ".75in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Independent Contractors and Third-Parties.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;We may have third-party agents, subsidiaries, affiliates and
                                    partners who may perform certain functions on our behalf, such as
                                    marketing, analytics, research, credit card processing, assisting with
                                    customer service, fraud protection, etc. Such third-party entities have
                                    limited access only to the personal information necessary to perform the
                                    roles for which they are associated with us. Such third-parties are
                                    strictly prohibited from using, selling, distributing or altering this
                                    data in any way, or using the information for any activity other than
                                    required to provide the requested services for us and the Site.
                                    </span>
                                </p>
                                <p className="MsoListParagraphCxSpMiddle">
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif'
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Legal Disclosure.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;In some instances, we may disclose and release certain information
                                    in response to any legal demand, such as subpoenas, court orders, or legal
                                    process, or to establish or exercise our legal rights or defend against
                                    legal actions or claims brought against us or the Site. We may share such
                                    information in order to investigate, prevent or take action in the event
                                    of any alleged illegal activities, suspected fraud, potential threats to
                                    the physical or financial safety of any person or entity, any violations
                                    of our terms of service, or as otherwise permissible under any applicable
                                    law.
                                    </span>
                                </p>
                                <p className="MsoListParagraphCxSpMiddle">
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif'
                                    }}
                                    >
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iv.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Permissible or Authorized HIPAA Disclosures.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp; In some instances, we may disclose and release certain protected
                                    health information of yours, obtained by us through the provision of the
                                    Services, to your employer or other designated third-parties, where such
                                    information would otherwise be protected from disclosure under
                                    HIPAA.&nbsp; Any and all such disclosures shall be made in strict
                                    accordance with the authorization you have provided us through a signed
                                    HIPAA disclosure authorization form permitting the same, and in accordance
                                    with the terms of this Privacy Policy.&nbsp; Your consent to the
                                    disclosure of such information, as authorized by you in the signed HIPAA
                                    disclosure authorization form, may be withdrawn by you at any time,
                                    through the provision of a written request to the company, which may be
                                    sent to the following email address: info@venilabs.com
                                    </span>
                                </p>
                                <p className="MsoListParagraphCxSpMiddle">
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif'
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        v.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Third-Party Advertisers.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;In some instances, we may collect certain personal information about
                                    you and share it with other third-parties, to the extent that you grant
                                    permissible upon collection of such information. We may show certain
                                    targeted advertisements on the Site during your use, based on personal
                                    information you provided to us upon registering your account. Advertisers
                                    (including ad serving companies) may assume that certain people who
                                    interact with, view or click on targeted ads, meet the targeting criteria
                                    (for example, women ages 18-24 from a particular geographic area). By
                                    clicking on or viewing a particular ad, you are thereby consenting to the
                                    possibility that the advertiser will make the assumption that you meet the
                                    targeting criteria used to determine whether to show you such an ad. We
                                    may gather certain personal information about you on behalf of a sponsor
                                    or advertiser who is facilitating a particular promotion. In such an
                                    instance, we will provide you with notice of such, at the time of the
                                    collection on the sponsor or advertiser’s behalf.&nbsp; Upon doing so, we
                                    may link you to the sponsor or advertiser’s privacy policy.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{ marginLeft: ".75in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        vi.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        Sharing Within Organization.
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;In some instances, we may share your personal information with any
                                    of our subsidiaries, joint ventures, or other companies or entities which
                                    fall under common control. In the event that we transition through merger,
                                    acquisition by another company, or sale of all or a portion of our assets,
                                    your personal information may be included and among the assets to be
                                    transferred in such a transition. You hereby acknowledge and agree that
                                    such transfers may occur, and that such transfers are permitted under the
                                    terms of this Privacy Policy.
                                    </span>
                                </p>
                                <p className="MsoListParagraphCxSpMiddle">
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif'
                                    }}
                                    >
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpLast"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        vii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Some various ways in which we may use your Personal Information are as
                                    follows:
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To provide content, materials, and other various forms of information as
                                    it pertains to the Services.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To verify your account and user identity and to comply with laws to which
                                    we are subject.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To contact you about the Services or the Company.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To provide customer or technical support.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To analyze and improve the features and performance of the Services and to
                                    analyze usage patterns.
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "2.0in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: "Symbol" }}>
                                    ·
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    To inform users about offers from the Company.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (b)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    We care about your privacy. We will not sell or share your Personal
                                    Information with third parties other than as expressly provided for in
                                    this Privacy Policy.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        3.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        THIRD PARTY LINKS
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    You may encounter links to third party websites, videos, pictures, and
                                    applications (“Third-Party Links”) when using the Services. We cannot
                                    control the content on these Third-Party Links and we can make no
                                    guarantees as to the protection and privacy of any information which you
                                    submit to these Third-Party Links. Please exercise caution when accessing
                                    Third-Party Links.&nbsp;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        4.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        CHILDREN
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <strong>
                                    <span style={{ fontSize: "10.0pt" }}>
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </strong>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    We do not knowingly collect Personal Information from any child under the
                                    age of 13. Further, you may not use the Site unless you are at least 18
                                    years of age.&nbsp;
                                    </span>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        background: "white"
                                    }}
                                    >
                                    You acknowledge and consent to taking such steps as are necessary in order
                                    to verify your identity and your age for the purposes of complying with
                                    applicable law, in order to use the Site
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    .&nbsp; If you believe we have collected Personal Information from a child
                                    under the age of 13, please contact us immediately.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        5.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        SECURITY
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    We use commercially reasonable methods of protecting your Personal
                                    Information.{" "}
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Nevertheless, we cannot guarantee the safety or security of any Personal
                                    Information. Further, transmission of Personal Information cannot be made
                                    completely secure. For that reason, you agree to transmit Personal
                                    Information to the Company solely at your own risk.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        6.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        DO NOT TRACK POLICY AND PROCEDURES
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Veni Labs{" "}
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    does not currently respond to “do not track” browser headers or specific
                                    “do not track” requests. &nbsp;You do have the ability to limit tracking
                                    through these third-party programs and by taking the actions listed below.
                                    &nbsp;The use of online tracking mechanisms by third-parties is governed
                                    by those third-parties’ own privacy policies, and not the terms included
                                    hereunder. If you prefer to prevent third-parties from setting and
                                    accessing cookies on your computer, you may adjust the settings of your
                                    own web browser to block the placement of cookies. You may remove yourself
                                    from the targeted advertising of companies by contacting the Network
                                    Advertising Initiative, or of companies participating in the Digital
                                    Advertising Alliance program by contacting them directly.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        7.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        INTERNATIONAL PRIVACY PRACTICES
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    The Company is primarily operated and managed on servers located and
                                    operated within the United States. In order to provide our Services to
                                    you, we may send and store your Personal Information outside of the
                                    country where you reside or are located, including to the United States.
                                    Accordingly, if you reside or are located outside of the United States,
                                    your Personal Information may be transferred outside of the country where
                                    you reside or are located, including countries that may not or do not
                                    provide the same level of protection for your Personal Information. We are
                                    committed to protecting the privacy and confidentiality of Personal
                                    Information when it is transferred. If you reside or are located within
                                    the European Economic Area and such transfers occur, we take appropriate
                                    steps to provide the same level of protection for the processing carried
                                    out in any such countries as you would have within the European Economic
                                    Area to the extent feasible under applicable law. By using and accessing
                                    our Services, users who reside or are located in countries outside of the
                                    United States agree and consent to the transfer to and processing of
                                    Personal Information on servers located outside of the country where they
                                    reside, and that the protection of such information may be different than
                                    required under the laws of their residence or location.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpMiddle"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        8.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        RETENTION, CORRECTION, AND DELETION OF PERSONAL INFORMATION
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Personal{" "}
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Information that we process shall not be kept for longer than is necessary
                                    for the relevant purpose. We will retain your Personal Information as
                                    follows:
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Personal
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Information
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    will be retained for as long as you have a Veni Labs member account (as
                                    described in our Terms and Conditions of Use) or as required by law or our
                                    own backup and security policies.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    We will comply with any user’s requests regarding access, correction,
                                    and/or deletion of the Personal Information we store in accordance with
                                    applicable law, including the EU General Data Protection Regulation
                                    (GDPR). To request that we correct, delete, or export any Personal
                                    Information, please contact us via email at info@venilabs.com
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>

                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    .{" "}
                                    </span>
                                </p>
                                <p className="Body" style={{ marginLeft: ".25in", textIndent: "-.25in" }}>
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        9.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        COOKIE POLICY
                                    </span>
                                    </b>
                                </p>
                                <p className="Body">
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    We use cookies on this Site and Service.&nbsp; By using the Service, you
                                    consent to the use of cookies.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        (b)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    Our Cookies Policy explains what cookies are, how we use cookies, how
                                    third-parties we may partner with may use cookies on the Service, your
                                    choices regarding cookies and further information about cookies.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        (c)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        What are cookies
                                        <br />
                                        <br />
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    Cookies are small pieces of text sent by your web browser by a website you
                                    visit. A cookie file is stored in your web browser and allows the Service
                                    or a third-party to recognize you and make your next visit easier and the
                                    Service more useful to you.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    Cookies can be "persistent" or "session" cookies.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        (d)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        How we use cookies
                                        <br />
                                        <br />
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    When you use and access the Service, we may place a number of cookies
                                    files in your web browser.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    We use cookies for the following purposes: to enable certain functions of
                                    the Service, to provide analytics, to store your preferences, to enable
                                    advertisements delivery, including behavioral advertising.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    We use variations of both session and persistent cookies on the Site and
                                    Service and we use different types of cookies to assist in running the
                                    Service.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iv.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    Essential cookies. We may use essential cookies to authenticate users and
                                    prevent fraudulent use of user accounts.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        {/* (e) */}
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        </span>
                                    </b>
                                    </span>
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        {/* Types of cookies we may use */}
                                        </span>
                                    </b>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{ marginLeft: ".75in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        &nbsp;
                                        </span>
                                    </b>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        {/* i. */}
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        </span>
                                    </b>
                                    </span>
                                    <span className="None">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        {/* The following cookies listed below are those which we may use to collect
                                        certain information about you. */}
                                    </span>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{ marginLeft: ".75in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span className="None">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    </span>
                                </p>
                                {/* <table
                                    className="MsoTableGrid"
                                    border={1}
                                    cellSpacing={0}
                                    cellPadding={0}
                                    style={{ marginLeft: ".75in", borderCollapse: "collapse", border: "none" }}
                                >
                                    <tbody>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            align="center"
                                            style={{
                                            textAlign: "center",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <b>
                                                <span
                                                style={{
                                                    fontSize: "10.0pt",
                                                    fontFamily: '"Times New Roman",serif',
                                                    color: "windowtext"
                                                }}
                                                >
                                                Cookie Name
                                                </span>
                                            </b>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            border: "solid windowtext 1.0pt",
                                            borderLeft: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            align="center"
                                            style={{
                                            textAlign: "center",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <b>
                                                <span
                                                style={{
                                                    fontSize: "10.0pt",
                                                    fontFamily: '"Times New Roman",serif',
                                                    color: "windowtext"
                                                }}
                                                >
                                                Cookie Type
                                                </span>
                                            </b>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            border: "solid windowtext 1.0pt",
                                            borderLeft: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            align="center"
                                            style={{
                                            textAlign: "center",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <b>
                                                <span
                                                style={{
                                                    fontSize: "10.0pt",
                                                    fontFamily: '"Times New Roman",serif',
                                                    color: "windowtext"
                                                }}
                                                >
                                                Cookie Description
                                                </span>
                                            </b>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            borderTop: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            borderTop: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            borderTop: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext",
                                                background: "yellow"
                                                }}
                                            >
                                                _____________
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            borderTop: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.8pt",
                                            border: "solid windowtext 1.0pt",
                                            borderTop: "none",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                        <td
                                        width={208}
                                        valign="top"
                                        style={{
                                            width: "155.85pt",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderBottom: "solid windowtext 1.0pt",
                                            borderRight: "solid windowtext 1.0pt",
                                            padding: "0in 5.4pt 0in 5.4pt"
                                        }}
                                        >
                                        <p
                                            className="Body"
                                            style={{
                                            textAlign: "justify",
                                            lineHeight: "normal",
                                            border: "none"
                                            }}
                                        >
                                            <span className="None">
                                            <span
                                                style={{
                                                fontSize: "10.0pt",
                                                fontFamily: '"Times New Roman",serif',
                                                color: "windowtext"
                                                }}
                                            >
                                                &nbsp;
                                            </span>
                                            </span>
                                        </p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table> */}
                                <p
                                    className="Body"
                                    style={{ marginLeft: ".75in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span className="None">
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        (f)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        </span>
                                    </b>
                                    </span>
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        Third-party cookies
                                        <br />
                                        <br />
                                        </span>
                                    </b>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    In addition to our own cookies, we may also use various third-parties’
                                    cookies to report usage statistics of the Service, deliver advertisements
                                    on and through the Site and Service, and so on.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        (g)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                            &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                        </span>
                                    </b>
                                    </span>
                                    <span className="None">
                                    <b>
                                        <span
                                        style={{
                                            fontSize: "10.0pt",
                                            fontFamily: '"Times New Roman",serif',
                                            color: "windowtext"
                                        }}
                                        >
                                        What are your choices regarding cookies
                                        <br />
                                        <br />
                                        </span>
                                    </b>
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    If you'd like to delete cookies or instruct your web browser to delete or
                                    refuse cookies, please visit the help pages of your web browser.
                                    <br />
                                    <br />
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                        }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    Please note, however, that if you delete cookies or refuse to accept them,
                                    you might not be able to use all of the features we offer, you may not be
                                    able to store your preferences, and some of our pages might not display
                                    properly.
                                    </span>
                                </p>
                                <p
                                    className="Body"
                                    style={{ marginLeft: "1.25in", textAlign: "justify", lineHeight: "normal" }}
                                >
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "windowtext"
                                    }}
                                    >
                                    &nbsp;
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        10.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        PERSONAL INFORMATION WILL BE SENT TO THE UNITED STATES
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    The Company is based in the United States and your Personal Information
                                    will be processed in the United States. The hosting facilities for your
                                    Personal Information are in the United States. United States laws may be
                                    less strict than those of your own country.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        11.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        YOUR RIGHTS UNDER THE EU GENERAL DATA PROTECTION REGULATION (GDPR)
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Your principal rights under the EU General Data Protection Regulation
                                    (GDPR) are as follows:
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        i.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right of Access: you have the right to be informed of, and request access
                                    to, the Personal Information we process about you.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        ii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right to Rectification: you have the right to request that we amend or
                                    update your personal data where it is inaccurate or incomplete.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right to Erasure: you have the right to have the Company delete your
                                    Personal Information.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        iv.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right to Restrict: you have the right to request that we temporarily or
                                    permanently stop processing your Personal Information.{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        v.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right to Object: you have the right to object to us processing your
                                    Personal Information on grounds relating to your particular situation or
                                    for direct marketing purposes.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        vi.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right to Data Portability: you have the right to request a copy of your
                                    Personal Information in electronic format and the right to transmit that
                                    personal data for use in another party’s service.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "1.25in",
                                    textAlign: "justify",
                                    textIndent: "-1.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        vii.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                    </b>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    Right not to be Subject to Automated Decision Making: you have the right
                                    to not be subject to a decision based solely on automated decision making,
                                    including profiling, where the decision would have a legal effect on you
                                    or produce a similarly significant effect.
                                    </span>
                                </p>
                                <p
                                    className="MsoListParagraph"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".25in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        12.
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        DATA CONTROLLER AND HOW TO CONTACT US
                                    </span>
                                    </b>
                                </p>
                                <p
                                    className="MsoListParagraphCxSpLast"
                                    style={{
                                    marginTop: "0in",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: ".75in",
                                    textAlign: "justify",
                                    textIndent: "-.25in",
                                    lineHeight: "normal"
                                    }}
                                >
                                    <b>
                                    <span
                                        style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                                    >
                                        (a)
                                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                                        &nbsp;&nbsp;&nbsp;{" "}
                                        </span>
                                    </span>
                                    </b>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        background: "white"
                                    }}
                                    >
                                    The Personal Information we collect and process is controlled by Veni
                                    Labs, Inc., a Florida corporation located in the United States. You may
                                    contact us at info@venilabs.com
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>

                                    </span>
                                    <span
                                    style={{
                                        fontSize: "10.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        background: "white"
                                    }}
                                    >
                                    .
                                    </span>
                                    <span style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}>
                                    {" "}
                                    </span>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
      }
    }

export default Policy;
