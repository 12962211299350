import React from 'react'

class Terms extends React.Component {

    componentDidMount() {
      window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <div className="ex-basic-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="text-container">

                            <div>
  <p
    className="western"
    align="center"
    style={{ marginBottom: "0.17in", lineHeight: "100%" }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>Veni Labs</b>
    </font>
  </p>
  <p
    className="western"
    align="center"
    style={{ marginBottom: "0.17in", lineHeight: "100%" }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>Terms of Service</b>
    </font>
  </p>
  <p
    className="western"
    align="center"
    style={{ marginBottom: "0.17in", lineHeight: "100%" }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <i>
        <b>Last modified on August 11, 2020</b>
      </i>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>1. Acceptance of Terms</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Venilabs.com (the “Site”) is owned and operated by Veni Labs, Inc., a
      Florida corporation (the “Company”), and the Company welcomes you to its
      online service (the "Service"). By using this Site or Service, you are
      agreeing to the following Terms of Service ("TOS"), whether or not you are
      a registered customer of the Company. The TOS may be updated from time to
      time without notice to you. However, you can always find the latest TOS at
      www.venilabs.com/termsconditions and should check regularly for updates and changes.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      In addition, when using the Service, you shall be subject to any posted
      policies, guidelines or rules applicable thereto. All such policies,
      guidelines and rules are hereby incorporated by reference into the TOS.
      You further agree that, except as otherwise expressly provided in this
      TOS, there shall be no third-party beneficiaries to these TOS.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        IF YOU DO NOT AGREE TO THE CURRENT TOS, PLEASE DO NOT USE THIS SERVICE,
        SINCE YOUR USE OF IT CONSTITUTES ACCEPTANCE OF ALL OF THE MOST CURRENT
        TOS FOR ITS USE.
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>2. Description of Service</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company provides a web and app-based Service in the form of a
      platform, which is made available to users, through which said users may
      register, request, and schedule an appointment to have a third-party,
      independent contractor technician (“Technician”) travel to a location of
      said user’s choice, and collect certain test samples or specimens from
      users, to have such samples or specimens transferred to, and tested at a
      licensed laboratory (“Laboratory”) with whom we have partnered. Depending
      on the Service selected, users may also be able to receive, through the
      Site or Service, reported results of such tests performed by said
      Laboratories. The platform may also contain{" "}
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      various types of content made available through the Service, which may be
      provided to users in the form of audio, video, and text features. This
      Content (as defined by Section 4 below) made available to users through
      the Site and Service is in no way considered to be any form of medical,
      consulting, or regulatory advice, and is provided strictly for
      entertainment purposes. In the event that any user hereof feels that they
      may require the services of a medical professional or specialist for any
      reason whatsoever, they are hereby advised to immediately seek those
      services from such appropriate medical professionals, as this Site and the
      Services provided thereon are in no way intended to be a substitute or
      replacement of the services or advice of such medical professionals.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      THE SERVICE IS PROVIDED "AS IS" AND THE COMPANY ASSUMES NO RESPONSIBILITY
      FOR TIMELINESS, ERRORS IN CONTENT OR RESULTS REPORTED OR CONTAINED
      THEREON, PERIODS OF INACTIVITY OR INABILITY TO USE THE SERVICE, OR FAILURE
      TO STORE ANY PARTICULAR INFORMATION, DATA, RESULTS, COMMUNICATIONS, OR
      WRITING. ADDITIONALLY, COMPANY PROVIDES ANY AND ALL INFORMATION OR CONTENT
      MADE AVAILABLE THROUGH THE SERVICE, WITHOUT WARRANTY OF ANY KIND AND AS
      SUCH, THE COMPANY IS NOT RESPONSIBLE OR LIABLE FOR THE ACCURACY, CONTENT,
      COMPLETENESS, LEGALITY, OR RELIABILITY OF SUCH CONTENT OR INFORMATION.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Unless explicitly stated otherwise, any newly added features which may
      augment or enhance the Service, including without limitation the release
      of new Company properties, are subject to the then current TOS.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      All users must abide by the TOS. If a user fails to follow any of the
      guidelines and/or rules of behavior, the Company can discontinue said
      user’s ability to use the Site or Service at any time, and for any reason.
      In addition, Company has the right, but not the obligation, to adjust,
      edit, or delete any materials, data, links, posts, or comments which
      Company may find objectionable in its sole discretion.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You may only use the Site and Service for purposes expressly permitted by
      this TOS. As a condition of your use of the Site or Service, you represent
      and warrant to Company that you will not use the Site and any Service for
      any purpose that is unlawful, offensive, and/or prohibited by this TOS.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You alone are solely responsible for any activity that takes place on the
      Site or through the Service under your name and password. If you become
      aware of any unauthorized use of your username and/or password it is your
      responsibility to notify the Company immediately. It is up to you to
      maintain the confidentiality of your password and username at all times.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You understand that you may receive business-related communications from
      Company such as Service announcements and account administrative notices,
      and you agree that these communications are not "unsolicited commercial
      email advertisements" and thus, subject to all applicable laws, you agree
      to receive such communications.
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      You may unsubscribe from the receipt of such email communications at any
      time by sending an email requesting such un-subscription and/or
      cancellation to an email address that shall be designated specifically for
      receipt of such requests, however, the fulfillment of this request may
      affect the functionality of the Service and its ability to perform as
      intended. Additionally, even in the event that you unsubscribe from the
      marketing emails, if you are registered with an account or have requested
      that the Company contact you about certain services, you authorize the
      Company to send you important notices about such services and any pending
      transactions relating to these services, to any email address you have
      provided to Company throughout the registration process, or at any other
      time thereafter. It is your sole responsibility to ensure that the email
      address on file remains up to date to be sure that important emails sent
      to you are not blocked or re-routed to spam folders.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      TCPA DISCLAIMER: Through your use of the Service, you also hereby
      expressly consent and agree to receive various electronic and other forms
      of communications from us, which may come in the form of phone calls or
      SMS text messages. The ways in which we shall communicate with you may
      include but not be limited to mediums in the form of phone calls, SMS text
      messages, posts, notices, or alterations on the Website, or through other
      electronic communication mediums. You hereby consent and agree that all
      agreements, notices, disclosures and other communications that we provide
      to you electronically shall suffice to satisfy any and all legal
      requirement that such communications are made in writing.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      As a user of the Service you also understand and agree that the Service
      may also include third-party links and/or advertisements. You agree that
      such third-party links and/or advertisements are not "unsolicited
      commercial email advertisements" and, subject to all applicable laws, you
      agree to receive such communications. You understand and agree that your
      correspondence or business dealings with, or participations in promotions
      of, advertisers found on or through the Service, including payment and
      delivery of services, and any other terms, conditions, warranties or
      representations associated with such dealings, are solely between you and
      the advertiser, and therefore subject to the advertiser’s terms of service
      and privacy policy. You agree that the Company shall not be responsible
      nor liable for any loss or damages incurred or sustained as the result of
      such dealings or as the result of the presence of such third-party links
      and/or advertisers on the Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <i>
        <b>
          YOU MUST BE AT LEAST 18 YEARS OF AGE TO ACCESS AND VIEW THE SITE, THE
          SERVICES OFFERED, AND ANY CONTENT INCLUDED THEREON. IF YOU ARE UNDER
          18 YEARS OF AGE, DO NOT USE THIS SERVICE FOR ANY PURPOSE AS IT IS NOT
          INTENDED FOR USE BY THOSE UNDER THE AGE OF LEGAL MAJORITY.
        </b>
      </i>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>3. Rules of Behavior</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not abuse, defame, harass, stalk, threaten, intimidate, or
      otherwise violate the legal rights of others.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not violate the privacy or publicity rights of others.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action which may subject other users or the public
      to any material that is inappropriate, defamatory, infringing, obscene,
      vulgar, libelous, racially or ethnically hateful, objectionable, or
      unlawful (or promote or provide instructional information about illegal
      activities or communications that could reasonably cause damage, loss,
      harm, or injury to any individual or group).
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action which may subject other users or the public
      to (a) anything that promotes or distributes pyramid schemes or chain
      letters, or (b) other disruptive, illegal or immoral communications of any
      kind.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action which could potentially exploit or harm
      children by exposing them to inappropriate content, asking for personally
      identifiable details or information, or otherwise exposing them to
      materials or information which in any way could cause damage, injury, or
      harm of any kind.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action which may subject other users or the public
      to anything which harvests or otherwise collects information about other
      users, including without limitation addresses, phone numbers, e-mail
      addresses, and/or financial information of any kind, without the users’
      prior express consent in each instance.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action that infringes any patent, trademark,
      copyright, trade secret or other intellectual property or other legal
      right of another user or of any third-party without prior written
      permission from said user or third-party in each instance, which written
      permission you agree to disclose to the Company at any time upon Company’s
      request (subject to all applicable laws). You shall not intentionally
      download any material that you know or should reasonably know cannot be
      distributed legally.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not impersonate another person, group of people, or entity at
      any time, and the prohibition of such activities shall also include not
      using anyone else's username or password.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not use the Site or Service for any illegal, immoral, or
      unauthorized purpose.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall abide by all applicable Federal, State and local laws at all
      times. If you are outside of the United States, you shall comply with any
      and all local laws as well with respect to your online conduct, including
      without limitation, the exportation of data to the United States or to
      your country or residence.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not modify, edit, or delete any Content or communications of the
      Site or Service and/or of other users of the Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not transmit any viruses, worms, defects, Trojan Horses or any
      items and/or computer code, files or programs of a destructive nature
      and/or otherwise designed to interrupt or limit the functionality of any
      computer software or hardware or telecommunications device.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not transmit or allow access or distribution of any spiders,
      robots or other information gathering computer programs or routines or
      their equivalents on the Site or in the Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not take any action which disrupts the normal flow of Content on
      the Site, or otherwise take any action which affects the ability of other
      people to engage in real-time activities through the Site.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not interfere with or disrupt the Site, the Service, the
      servers, or networks connected with the Site or Service, or disobey any
      requirements, procedures, policies or regulations of networks connected to
      the Site or Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not create member accounts under any false or fraudulent
      pretenses (including by automated means).
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not retrieve, store or collect personal information about any
      user for any unauthorized purpose, and may not retrieve, store, or collect
      personal information about any user for any valid purpose without such
      user’s prior written consent in each instance (and acknowledgment that
      they are at least 13 years of age).
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not engage in any "spamming" of any kind, including without
      limitation ad spamming.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    •{" "}
    <font size={2} style={{ fontSize: "10pt" }}>
      You shall not use the Company’s or Site’s name or trademarks or related
      trade names, which you acknowledge here to be valid, subsisting and
      enforceable without impediment, and you will not expressly or by
      implication create the false or misleading impression that the Company or
      the Site is associated with, or endorses, or is in any way connected with
      you or your business.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      WHILE THE COMPANY EXPLICITLY PROHIBITS THE ABOVE CONDUCT YOU ACKNOWLEDGE
      AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND CONTENT AND THAT YOU
      USE THE SERVICE ENTIRELY AT YOUR OWN RISK, AND THAT THE COMPANY SHALL HAVE
      NO LIABILITY OF ANY KIND FOR SUCH CONDUCT AS CARRIED OUT BY ANY
      THIRD-PARTY AT ANY TIME, UNLESS EXPRESSLY SET FORTH TO THE CONTRARY BY THE
      COMPANY IN WRITING.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      YOU UNDERSTAND AND AGREE THAT IF YOU VIOLATE ANY OF THE RULES OF BEHAVIOR,
      THE COMPANY MAY TERMINATE YOUR USE OF THE SERVICE IMMEDIATELY WITHOUT
      PRIOR NOTICE AND WITHOUT ANY RIGHT TO A REFUND, SET-OFF, OR HEARING.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>4. Content</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      As a user of the Site or Service, you may be provided access to a variety
      of materials and information, which may include, but shall not be limited
      to certain text, audio, video, photographs, graphics, and other
      informational data. All materials contained on the Site, whether uploaded
      by the Company or by any third-party, shall be considered ("Content").
      Company does not warrant or guarantee any Content uploaded by the Company,
      nor does it screen, warrant, guarantee, or endorse any Content uploaded by
      any third-party.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company shall have no liability of any kind with respect to any
      Content listed on the Site, or your use or reliance thereon. You agree
      that you must assess and bear all risks associated with your use of any
      Content of the Site, or contained in any links found on the Site. In this
      regard, you may not rely on any Content created by the Company or by any
      third-party, or otherwise created, distributed and displayed on any part
      of the Service. The Company does not control or monitor all of the Content
      posted via the Service and, as such, does not guarantee the accuracy,
      integrity or quality of such Content.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You acknowledge that Company may or may not pre-screen Content but that
      the Company and its designees shall have the right, but not the
      obligation, in its sole discretion to pre-screen, refuse, move, edit,
      and/or delete any Content that violates these TOS and/or is otherwise
      objectionable as determined by the Company in its sole discretion.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company may preserve and store your account information if it believes
      in good faith that such preservation is necessary to comply with legal or
      regulatory processes, respond to claims that your actions may violate the
      rights of third-parties, and/or to protect the rights, property or
      personal safety of the Company, the Site, the Service users, or the
      public.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company respects the intellectual property of others and the Company
      requests its users do the same. In certain circumstances and at its
      discretion, the Company may, but is not obligated to disable, suspend and
      terminate the accounts of members who may be infringing on the rights of
      others. If you believe that your intellectual property has been copied in
      a way that constitutes copyright infringement, or your intellectual
      property rights have otherwise been violated, please contact the Company
      as soon as practically possible with the following information:
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.75in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      1. an electronic or physical signature of the person authorized to act on
      behalf of the owner of the copyright or other intellectual property
      interest;
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.75in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      2. a description of where the material you claim is infringing is located
      on the site;
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.75in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      3. your name, address, telephone number, and email address;
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.75in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      4. a statement claiming that you have a good faith belief that the
      disputed use has not been authorized; and
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.75in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      5. a statement by you made under penalty of perjury, that the above
      information is accurate and that you are the copyright or intellectual
      property holder or are authorized to act on behalf of the holder.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Upon receiving a sufficient and proper notification of alleged copyright
      infringement as described above, the Company will remove or disable access
      to the allegedly infringing content and promptly notify the alleged
      infringer of your claim. Please submit your statement to the Company by
      mail, fax or email as set forth below:
    </font>
  </p>
  <p
    style={{
      marginLeft: "1.5in",
      marginBottom: "0in",
      lineHeight: "100%",
      background: "#ffffff"
    }}
  >
    <span style={{ display: "inline-block", border: "none", padding: "0in" }}>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b>
          <br />
          Veni Labs, Inc.
        </b>
      </font>
    </span>
  </p>
  <p
    style={{
      marginLeft: "1.5in",
      marginBottom: "0in",
      lineHeight: "100%",
      background: "#ffffff"
    }}
  >
    <span style={{ display: "inline-block", border: "none", padding: "0in" }}>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b>
          Venilabs.com
          <br />
          Email: info@venilabs.com
        </b>
      </font>
    </span>
    <font size={2} style={{ fontSize: "10pt" }}>
      <b></b>
    </font>
  </p>
  <p
    style={{
      marginLeft: "1.5in",
      marginBottom: "0in",
      lineHeight: "100%",
      background: "#ffffff"
    }}
  >
    <span style={{ display: "inline-block", border: "none", padding: "0in" }}>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b>Phone: 954-906-9480</b>
      </font>
    </span>
    <font size={2} style={{ fontSize: "10pt" }}>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "1.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <span style={{ display: "inline-block", border: "none", padding: "0in" }}>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b>
          Veni Labs, Inc. Copyright Agent
          <br />
        </b>
      </font>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b>legal@venilabs.com</b>
      </font>
      <font size={2} style={{ fontSize: "10pt" }}>
        <b></b>
      </font>
    </span>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>5. Payments</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      In order to use or access certain features or limited Content through the
      Site and Services offered by the Company, you may be required to pay a
      services fee, depending on the type of subscription and user account you
      have enrolled in through the Site and Service. Such fees are typically
      billed at a flat rate, on a per-visit or per-test basis, depending upon
      the Service enrolled in, and pursuant to the current pricing structure of
      each Service offered at the time of enrollment. The descriptions of the
      Services and Content available for enrollment along with the costs of each
      Service shall be detailed on the [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      name of page where each type of User Account will be described
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ] page of the Site. The costs of these Services and the access/amenities
      provided with the subscription, access to, and use of the Services, shall
      be subject to change at any time at the Company’s discretion. Any updates
      or changes in pricing will be posted on the [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      name of page where each type of User Account will be described
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ] page of the Site. In the event that you have elected to enroll in any
      Service which is billed on a recurring basis, the Services and
      corresponding subscription fee you select may automatically renew at the
      end of each billing cycle, if the Services are not cancelled prior to the
      last business day before the next scheduled billing date. In order to make
      said payments required for the Services offered, you may be asked to input
      personal and/or business banking information, including but not limited
      to, credit card information, billing addresses, bank account information,
      or account information for other payment Services offered, such as PayPal
      or other similar payment methods made available at that time. The Company
      hereby reserves the right to add, retract, change, or discontinue the
      allowance of the use of any particular payment method offered on the
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      Site
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      , in Company’s sole discretion. The processing of such payments may be
      made through the following third-party processing company:
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <b> Stripe</b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      , and as such, the processing of such payments shall be subject to said
      third-party’s terms of service and privacy policy which can be found here:
      [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      third-party TOS and PP link
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ]. The third-party processing company used by Company to process such
      payments may be changed at any time within Company’s sole discretion, with
      or without notice to you.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>6. Links</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Through the Service, the Company, other users, or other third-parties may
      provide links to other websites or resources, which are not maintained by
      or related to the Site or Service. Links to such sites are provided as a
      service to the users and are not sponsored by, endorsed or otherwise
      affiliated with the Company. The Company has no control over these sites
      and their content, and makes no representations or warranties about the
      content, completeness, quality or accuracy of the links, materials or
      information contained on any such website. Therefore, you acknowledge and
      agree that the Company is not responsible for the availability of such
      links, resources and content, and does not endorse, and is not responsible
      or liable for, any content, advertising, products, or other materials made
      available on or from these linked websites. You also acknowledge and agree
      that the Company is not responsible or liable, directly or indirectly, for
      any damage or loss caused by or alleged to have been caused by, or in
      relation to, reliance upon any statements or content displayed thereon,
      the use of any materials, goods or services offered through these links or
      any failures and/or disruption to your computer system that results from
      your use of any such links, or for any intellectual property or other
      third-party claims relating to your posting or using such links.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>7. Indemnity</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You agree to indemnify, defend, and hold the Company, its subsidies,
      affiliates, officers, agents, and other partners and employees, harmless
      from any and all loss, cost, injury, liability, claims, damages, or demand
      of any kind, including actual attorney's fees and related costs, made by
      or arising out of your use of the Site or Service in violation of these
      TOS and/or your violation of any rights of another or any applicable law,
      rule or regulation.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>8. No Resale of Service</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You agree not to, without the express prior written consent of the Company
      in each instance: reproduce, duplicate, copy, sell, resell, or exploit,
      for any commercial or other purposes, any portion of the Service or
      Content or other information or materials of any kind that you do not own.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>9. Modification and Termination of the Service</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company reserves the right at any time and from time to time to modify
      or discontinue, temporarily or permanently, the Service (or any part
      thereof) with or without notice. You agree that the Company will not be
      liable to you or any third-party for the consequences of any modification,
      suspension or discontinuance of the Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>10. Company Privacy Policy</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The storage and any use of personal and other certain information shall be
      subject to Company’s Privacy Policy. As a condition of using the Service
      you agree to the terms of the Privacy Policy, as it may be changed and
      updated from time to time. The Company’s Privacy Policy, which is
      incorporated herein by reference, is located at [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <a href="/PrivacyPolicy">Privacy Policy</a>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ]. You agree that your use of this Service is subject to the Privacy
      Policy, and therefore, agree that you will not use this Service unless and
      until you review the Privacy Policy and agree with its terms in its
      entirety.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>11. Registration</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      In order to use certain aspects of the Service you may be required to
      register for an account. The Company refers to registered users as
      “members.” Registered users are subject to the following specific terms in
      addition to all of the other terms in these TOS:
    </font>
  </p>
  <ul>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          In consideration of your use of the Service, you represent that you
          are of legal age to form a binding contract, which is eighteen (18)
          years of age in the United States, and are not a person barred from
          receiving the Services under the laws of the United States or other
          applicable jurisdiction.
          <br />
        </font>
        <br />
      </p>
    </li>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          You agree to provide true, accurate, current and complete information
          as required on the Service's registration form. If you provide any
          information that is untrue, incomplete, not current or inaccurate, the
          Company has the right to suspend or terminate your account and refuse
          your current or future use of the Service (or any portion thereof).
          <br />
        </font>
        <br />
      </p>
    </li>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          You understand, acknowledge, and agree that in order to participate in
          some of the Services offered through the Site, you may be required to
          provide company with personal/financial information which may include
          but shall not be limited to information pertaining to financial
          institutions you hold accounts with, bank account information, and
          credit card account information.
        </font>
      </p>
    </li>
  </ul>
  <p
    align="justify"
    style={{
      marginLeft: "1.25in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <br />
  </p>
  <ul>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          You agree that the Company may, for any reason in its sole discretion,
          and without prior notice, immediately terminate your account, any
          associated username and/or access to the Service. Cause for such
          termination may include, but shall not be limited to:(i) a breach or
          violation of the TOS or other Company policies, guidelines, or rules
          (including without limitation the Privacy Policy), (ii) extended
          periods of inactivity, (iii) your engagement in fraudulent or illegal
          activity, (iv) unexpected technical or security issues, and (v)
          requests by law enforcement or other government agencies. You also
          agree that any termination is in Company’s sole discretion and that
          the Company will not be liable to you or any third-party for any
          termination of your account, password, username, deletion of
          materials, results, or information, and/or access to the Service.
          <br />
        </font>
        <br />
      </p>
    </li>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          You agree that your account is non-transferable and any rights to your
          account, password, username, terminate upon your death or disability
          and/or termination of account for any reason.
          <br />
        </font>
        <br />
      </p>
    </li>
    <li>
      <p
        align="justify"
        style={{
          marginTop: "0.08in",
          marginBottom: "0in",
          lineHeight: "0.18in"
        }}
      >
        <font size={2} style={{ fontSize: "10pt" }}>
          You are responsible for any activities that take place under your
          username and password. If you become aware of any unauthorized use of
          your password or account, or any other breach of security, contact the
          Company immediately. It is up to you to maintain the confidentiality
          of your password and account. The Company is not responsible or liable
          for any loss or damage arising from your failure to comply with the
          provisions of these TOS.
        </font>
      </p>
    </li>
  </ul>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>12. Practices regarding Use and Storage</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You acknowledge that the Company may establish general practices and
      limits regarding use of the Service including, without limitation, the
      maximum number of chat messages, email messages, or other communications
      which may be sent or received from an account of the Service, the maximum
      size of any chat messages, email messages, or other communications that
      may be sent from or received by an account of the Service, the maximum
      amount of disk space that will be allotted on Company’s servers on your
      behalf, and the maximum number of times and duration for which you may
      access the Service in a given time. You agree that the Company has no
      responsibility or liability for the deletion or failure to store or
      transmit any data, test results, materials, messages or communications, or
      any other content maintained or transmitted by the Service. You hereby
      acknowledge and agree that the Company may record, track, and store any
      and all communications transmitted through the platform, but is under no
      obligation to do so, and shall have no liability to you or any third-party
      for the deletion of the same. You acknowledge and agree that the Company
      reserves the right to log off accounts and deactivate usernames and
      accounts that are inactive for an extended period of time. You further
      acknowledge that the Company has the right to modify these practices and
      limits from time to time.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>13. Company Proprietary Rights; Trademarks and Copyrights</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You acknowledge and agree that the Service and any necessary software used
      in connection with the Service ("Software") contain proprietary and
      confidential information that is the property of the Company and its
      licensors, and is protected by applicable intellectual property and other
      laws. No rights or title of to any of the Software used in connection with
      any Service is provided, transferred or assigned to you. You further
      acknowledge and agree that content contained in advertisements or
      information presented to you through the Service or advertisers is
      protected by copyright, trademarks, service marks, patent, privacy, and/or
      other proprietary rights and laws. Except as expressly authorized by the
      Company or advertisers, you agree not to modify, rent, lease, loan, sell,
      distribute or create derivative works based on the Service or Software, in
      whole or in part, at any time. You also acknowledge Company’s exclusive
      rights in the Company’s trademark and service mark. Trademarks, service
      marks, logos, and copyrighted works appearing on the Company are the
      property of the Company or the party that provided such intellectual
      property to the Company. The Company and any party that provides
      intellectual property to the Company shall retain all rights with respect
      to any of their respective intellectual property appearing on the Site or
      through the Service, and no rights in such materials are transferred or
      assigned to you, in whole or in part, at any time. You hereby agree and
      acknowledge that any and all Company logos, branding, or tradenames posted
      on the Site and/or platform shall not be removed or altered in any way by
      you or any third-party, in conjunction with your use of the Service.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>14. Disclaimer of Warranties</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The use of the Service is at your sole risk. The Service is provided on an
      "as is" and "as available" basis. THE COMPANY EXPRESSLY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
      LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
      PARTICULAR PURPOSE, AND NON-INFRINGEMENT.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      THE COMPANY MAKES NO WARRANTY THAT (1) THE SERVICE OR CONTENT OFFERED
      THEREON WILL MEET YOUR REQUIREMENTS, (2) THE SERVICE WILL BE
      UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE, (3) THE RESULTS OF USING THE
      SERVICE ITSELF, CONTENT OR INFORMATION OFFERED THROUGH THE SERVICE, OR THE
      RESULTS OF ANY TESTS ADMINISTERED AND/OR SAMPLES COLLECTED WILL BE
      ACCURATE OR RELIABLE, (5) THE QUALITY OF ANY CONTENT, SERVICES,
      INFORMATION, OR OTHER MATERIAL ACCESSED, PURCHASED, OR OBTAINED THROUGH
      YOUR USE OF THE SERVICE WILL MEET YOUR EXPECTATIONS, (6) THAT ANY
      COMMUNICATIONS, DATA, OR TEST RESULTS WILL BE STORED OR PRESERVED FOR ANY
      GIVEN PERIOD OF TIME, (7) THAT ANY COMMUNICATIONS, DATA, OR TEST RESULTS
      WILL BE TIMELY REPORTED OR SHARED WITH THE INTENDED PARTIES, OR REPORTED
      OR SHARED WITH THE INTENDED PARTIES AT ALL, OR (8) THAT THE SITE OR
      SOFTWARE WILL BE ERROR-FREE AND/OR ANY ERRORS IN THE SITE OR SOFTWARE WILL
      BE CORRECTED.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      No advice or information, whether oral or written, obtained by you from
      the Company or through or from the Site or Service, shall create a
      warranty not expressly stated in these TOS.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>15.</b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}></font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>Insurance</b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}></font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You hereby acknowledge and understand that Technicians and Laboratories
      with whom the Company may contract, are required to procure or cause to be
      procured and maintain or cause to be maintained in full force and effect
      at all times during the term of their engagement with Company, insurance
      coverages sufficient to satisfy any and all federal, state, county, and
      municipality requirements, as necessary to remain in full compliance with
      any and all applicable laws throughout the performance of the Services
      contemplated hereunder. You hereby acknowledge and understand that Company
      is not required to, and thereby may not carry any insurance related to
      coverage for the Services contemplated hereunder.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      textIndent: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>16. Technician Screening</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The contract of any Technician working with the Company to administer such
      collections or tests as contemplated hereunder, may be contingent upon
      such Technician’s satisfactory completion and passing of certain screening
      that includes criminal background, reference checks, employment and
      education history verification, medical exams, drug testing, credit
      checks, and/or other applicable screenings.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>17. Limitation of Liability</b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <br />
    </font>
    <br />
  </p>
  <p
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginBottom: "0in",
      lineHeight: "100%",
      orphans: 0,
      widows: 0
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      IN NO EVENT SHALL THE COMPANY, ITS DIRECT OR INDIRECT SUBSIDIARIES,
      AFFILIATES, AGENTS, EMPLOYEES, ATTORNEYS IN LAW OR IN FACT, OR
      REPRESENTATIVES BE LIABLE FOR ANY INCIDENTAL, SPECIAL, INDIRECT, PUNITIVE,
      EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, INCLUDING,
      WITHOUT LIMITATION, DAMAGES FOR INJURY OR DEATH, PROPERTY DAMAGE, LOSS OF
      PROFITS, LOSS OF OR DAMAGE TO DATA, LOSS OF OR DAMAGE TO SPECIMENS OR TEST
      RESULTS, INACCURACY OF TEST RESULTS, BUSINESS INTERRUPTION OR ANY OTHER
      COMMERCIAL OR FINANCIAL DAMAGES, LOST REVENUES, OR OTHER LOSSES OF ANY
      KIND, SUSTAINED BY YOU OR ANY THIRD-PARTY, IN CONNECTION WITH OR IN ANY
      WAY RELATED TO YOUR ENGAGEMENT WITH, RECEIPT OF, OR INABILITY TO RECEIVE
      THE SERVICES CONTEMPLATED HEREUNDER, HOWEVER CAUSED, REGARDLESS OF THE
      THEORY OF LIABILITY (CONTRACT, TORT OR OTHERWISE), EVEN IF WE HAVE BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE
      COMPANY’S TOTAL LIABILITY TO YOU OR ANY THIRD-PARTY FOR ALL DAMAGES EXCEED
      ONE-THOUSAND DOLLARS ($1,000.00).
    </font>
  </p>
  <p style={{ marginLeft: "0.5in", marginBottom: "0in", lineHeight: "100%" }}>
    <br />
  </p>
  <p
    align="justify"
    style={{ marginLeft: "0.5in", marginBottom: "0in", lineHeight: "100%" }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      YOU AGREE THAT THE COMPANY IS A MERE AGENT FOR TECHNICIANS AND
      LABORATORIES WHO WILL ACTUALLY BE PROVIDING ANY SAMPLE COLLECTION OR
      TESTING SERVICES AS CONTEMPLATED HEREUNDER. ANY AND ALL CLAIMS REGARDING
      ANY FAILURE OR BREACH WITH RESPECT TO THE SERVICES CONTEMPLATED HEREUNDER
      BY YOU OR ANY THIRD-PARTY, SHALL BE STRICTLY LIMITED TO CLAIMS AGAINST
      SUCH TECHNICIANS AND LABORATORIES. THE COMPANY HEREBY DISCLAIMS ANY
      LIABILITY, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
      FOR ANY DIRECT, PUNITIVE, SPECIAL, CONSEQUENTIAL, INCIDENTAL OUR INDIRECT
      DAMAGES, INCLUDING BODILY INJURY,OR DEATH IN CONNECTION WITH THE SERVICES
      CONTEMPLATED HEREUNDER, INCLUDING WITHOUT LIMITATION, LIABILITY FOR ANY
      ACT, ERROR, OMISSION, INJURY, LOSS, ACCIDENT, DELAY OR IRREGULARITY WHICH
      MAY BE INCURRED THROUGH THE FAULT, NEGLIGENT OR OTHERWISE, OF YOU OR ANY
      SUCH THIRD PARTY, AND YOU HEREBY EXONERATE THE COMPANY FROM ANY LIABILITY
      WITH RESPECT TO THE SAME.{" "}
    </font>
  </p>
  <p
    align="justify"
    style={{ marginLeft: "0.25in", marginBottom: "0in", lineHeight: "100%" }}
  >
    <br />
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      YOU FURTHER ACKNOWLEDGE AND AGREE THAT INTERACTING DIRECTLY WITH
      TECHNICIANS PERFORMING SUCH SAMPLE COLLECTIONS OR TESTS MAY EXPOSE YOU TO
      COMMUNICABLE DISEASES INCLUDING, WITHOUT LIMITATION, COVID-19. YOU AGREE
      TO WEAR PROPER PERSONAL PROTECTIVE EQUIPMENT (PPE) AND TO FOLLOW ALL
      APPROPRIATE SAFETY PROTOCOLS AT ALL TIMES WHEN INTERACTING WITH SUCH
      TECHNICIANS AND/OR RECEIVING ANY SERVICES CONTEMPLATED HEREUNDER. YOU
      ACKNOWLEDGE THAT EVEN WITH THE BEST RISK MITIGATION, IT IS IMPOSSIBLE TO
      ENSURE PROTECTION FROM COMMUNICABLE DISEASES AND YOU EXPRESSLY ASSUME THE
      RISK OF SICKNESS, INJURY, AND/OR DEATH RESULTING FROM COMMUNICABLE
      DISEASES (INCLUDING, WITHOUT LIMITATON, COVID-19) WHEN INTERACTING WITH
      SAID TECHNICIANS.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>18. Information Pertaining to Medical Information or Test Results</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      If you receive or request any news, messages, or other information from
      the Site or Service concerning any medical and/or test regulations
      inquiries/materials with respect to the content or information displayed
      or offered through the Site or Service, remember that the Site and Service
      is provided for entertainment, informational, and educational purposes
      only, and no Content included or information made available through the
      Site or Service is intended for, nor shall the same constitute any medical
      consulting or medical advisory services of any sort. The Company and its
      licensors shall not be responsible or liable for the accuracy, usefulness
      or availability of any test results or information transmitted or made
      available via the Site or Service, and shall not be responsible or liable
      for any decisions or actions made by you or any users based on such test
      results, information or Content.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>19. Assumption of Risk</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <span style={{ background: "#ffffff" }}>
        By engaging the Company’s Services, buying, using, or allowing the use
        of any and all tests, test materials or products (“Test(s)”) found or
        offered through the Site or the Service, you understand and agree that
        Tests such as those offered on the Site can be associated with a certain
        level of risk, including but not limited to the exposure to communicable
        diseases and, to the extent permitted by law, YOU EXPRESSLY AND
        VOLUNTARILY ASSUME THE RISK OF DEATH OR OTHER PERSONAL INJURY SUSTAINED
        WHILE USING SUCH PRODUCTS, WHETHER OR NOT CAUSED BY THE NEGLIGENCE OR
        OTHER FAULT OF the Company, including but not limited to any Test
        malfunction from whatever cause, or any other fault of the Company, any
        Technician, any Laboratory, any contractor or agent of the Company, or
        any other third-party vendor. Additionally, you agree to indemnify,
        defend, and hold the Company&nbsp;harmless from any claims by you or any
        third-party, arising from any use or misuse of such Tests offered
        through the Site or Service.
      </span>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <b></b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <span style={{ background: "#ffffff" }}>
        Use and misuse of Tests offered through the Site or Service
        may&nbsp;involve serious risks including injury, disability and death.
        In purchasing such Tests and/or engaging in the Services contemplated
        hereunder, you thereby agree to assume all risks.
      </span>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>20. Medical Disclaimer</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        <span style={{ background: "#ffffff" }}>
          The Site or Service does not contain medical advice, nor does Company
          provide any medical advice or medical services. The Content of the
          Site or Service, such as reported test results, text, graphics, images
          and other material are intended for entertainment, informational, and
          educational purposes only and not for the purpose of rendering medical
          advice or treatment advice of any sort. The Content of the Site or
          Service are not intended to substitute for professional medical
          advice, diagnosis or treatment. Although the Company makes efforts to
          keep information on the Site updated, the Company cannot guarantee
          that any information on the Company’s Site reflects the most
          up-to-date research or information. Although the Company makes efforts
          to ensure accurate reporting of test results related to specimens
          collected, the Company cannot guarantee the accuracy or reliability of
          any such test results.{" "}
        </span>
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        <span style={{ background: "#ffffff" }}>
          Please consult your physician for personalized medical advice. Always
          seek the advice of a physician or other qualified healthcare provider
          with any questions regarding a medical condition. Never disregard or
          delay seeking professional medical advice or treatment because of
          something you have read on the Company’s Site or through the Service,
          including any test results conveyed to you through the Company, from
          any Technician, or from any Laboratory the Company has partnered with.
        </span>
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        <span style={{ background: "#ffffff" }}>
          Before participating in any tests or using any products, taking any
          medications, over-the-counter drugs, supplements or herbs, or taking
          any other action, always consult a physician for a thorough
          evaluation. The Company does not endorse any particular diagnostic
          tests, products, medications, remedies, vitamins or herbs of any kind.
          A qualified physician should make a decision based on each person's
          symptoms, medical history, and current prescriptions. Any test
          results, product or medication summaries provided do not include all
          of the information important for any person to use in the potential
          course of diagnosis or treatment or any illness, disease or condition,
          and should not be used as a substitute for professional medical
          advice. A licensed physician should be consulted concerning any
          questions that you have.
        </span>
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        <span style={{ background: "#ffffff" }}>
          In a medical emergency call 911 immediately. The Company does not
          recommend or endorse any specific test, physician, product, procedure,
          opinion or any other information provided on its Site or through the
          Service. Reliance on any information provided by the Company, its
          employees, any third-parties, or any other visitors to the Site, is
          solely at your own risk.
        </span>
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{ marginTop: "0.14in", marginBottom: "0in", lineHeight: "0.18in" }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        21. Disclaimer of Florida Clinical Laboratory Personnel License Status
      </b>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}></font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      You hereby acknowledge and agree that the Company does not have, nor does
      Company hold itself out as having or purporting to have, a Clinical
      Laboratory Personnel License. The Company is NOT a laboratory, does not
      carry any licensing required by such laboratories under Federal, State
      and/or local applicable law, and does not test, control, process, store,
      or perform any other activity with any specimen collected from you by any
      Technician or Laboratory with whom the Company is associated. Any and all
      drawings, tests, or collections are performed by independent contractors
      who purport to have, and have represented and warranted to the Company
      that they have obtained and currently maintain any and all necessary
      licensing and/or certifications to do so. Any and all drawing, testing,
      processing, and storage of any specimens collected by such Technicians,
      shall be performed by third-party Laboratories who are sufficiently
      licensed and insured to perform such activities. You hereby agree to not
      take any action or make any statement, whether written or oral, which may
      bring any third-party under the impression that the Company performs such
      activities, or that the Company holds itself out to be sufficiently
      licensed and/or insured to do so.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>22. Personally Identifiable Information</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company cautions you against giving out any personally identifying
      information about yourself, your children, or any other person in the use
      of any third-party link or interaction accessed through the Site or
      Service. In an effort to preserve your privacy, the Company agrees that it
      will treat any personally identifying information that you submit through
      this site in accordance with the terms outlined in its Privacy Policy
      located at [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      <a href="/PrivacyPolicy">Privacy Policy</a>
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ].
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>23. Disclosures Required by Law</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The Company reserves the right at all times to disclose any information,
      including personally identifiable information about you, as necessary to
      satisfy any applicable law, regulation, legal process or governmental
      request. The Company reserves the right to fully cooperate with any law
      enforcement authorities or court order requesting or directing the Company
      to disclose the identity of anyone posting any content, or publishing or
      otherwise making available any materials that are believed to violate the
      TOS. The Company, may but is not obligated to, use reasonable efforts to
      notify you in connection with any such inquiry; provided, however, that
      the inquiry in question is not confidential, and further provided that the
      Company shall have no duty to disclose such information and therefore
      shall not be liable to you in connection with any non-disclosure.{" "}
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      BY ACCEPTING THESE TOS YOU WAIVE ALL RIGHTS AND AGREE TO HOLD COMPANY
      HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY COMPANY DURING
      OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A
      CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY, OR ANY LAW
      ENFORCEMENT OR REGULATORY AUTHORITIES.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      textIndent: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>24. Disclosure of HIPAA Protected Information </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>
        You hereby understand, acknowledge, and agree that depending upon which
        of the Services offered by the Company you have elected to enroll in,
        certain protected health information of yours, obtained by the Company
        through the provision of the Services contemplated hereunder, may be
        shared with or reported to certain third-parties, including but not
        limited to your employer in the case of any Services provided by Company
        at your employer’s request, and that such disclosures will be in strict
        accordance with the terms of the Company’s Privacy Policy, and the
        separate HIPAA disclosure authorization form signed and executed by you,
        now referenced and incorporated herein.
      </b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>25. Disputes</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      If there is any dispute about or involving the Company and/or the Service,
      you agree that any dispute shall be governed by the laws of the State of
      Florida, without regard to conflict of law provisions. You agree to
      personal and exclusive jurisdiction by and venue in the state and federal
      courts of the [
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      Preferred County
    </font>
    <font size={2} style={{ fontSize: "10pt" }}>
      ], Florida.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM
      OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF THE SERVICE OR
      THESE TOS MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF
      ACTION AROSE OR BE FOREVER BARRED.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.25in",
      marginTop: "0.14in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      <b>26. Miscellaneous Terms; General Information</b>
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      These TOS govern the terms and conditions of your use of the Site and the
      Service, and supersede all prior or contemporaneous communications and
      proposals, whether electronic, oral or written, between you and the
      Company with respect to the Site or the Service. Notwithstanding the
      foregoing, you may also be subject to additional terms and conditions,
      posted policies (including but not limited to the Company’s Privacy
      Policy), HIPAA authorization forms, or any other guidelines, or rules that
      may apply when you use or purchase certain Services, Tests, Content or
      products from the Site or certain elements of the Service, affiliate or
      advertiser services, third-party content or third-party software. The
      Company may revise these TOS at any time by updating this posting.
      Accordingly, you should review the TOS periodically to determine if any
      changes have been made. Your continued use of this Site after any changes
      have been made to the TOS signifies and confirms your acceptance of any
      such changes or amendments to these TOS.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The failure of the Company to exercise or enforce any right or provision
      of these TOS shall not operate as a waiver of such right or provision. Any
      waiver of the TOS by the Company must be in writing and signed by an
      authorized representative of the Company to be effective.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      If any provision of these TOS is found by the applicable court of
      competent jurisdiction to be invalid, the parties' intentions as reflected
      in the provision, and the other provisions of these TOS, shall remain in
      full force and effect.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      The section titles throughout these TOS are for convenience only and have
      no legal or contractual effect.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Nothing contained in these TOS shall be construed to constitute either
      party as a partner, joint-venturer, employee or agent of the other party,
      nor shall either party hold itself out as such. Neither party has any
      right or authority to incur, assume or create, in writing or otherwise,
      any warranty, liability or other obligation of any kind, express or
      implied, in the name of or on behalf of the other party, it being intended
      by both parties that each is and shall remain independent contractors (to
      the extent applicable), and therefore, responsible for its own actions.
    </font>
  </p>
  <p
    className="western"
    align="justify"
    style={{
      marginLeft: "0.5in",
      marginTop: "0.08in",
      marginBottom: "0in",
      lineHeight: "0.18in"
    }}
  >
    <font size={2} style={{ fontSize: "10pt" }}>
      Please immediately report any violations of these TOS to Company at legal@venilabs.com
    </font>
  </p>
  <p className="western" style={{ marginBottom: "0in", lineHeight: "100%" }}>
    <br />
  </p>
  <div title="footer">
    <p
      className="western"
      align="center"
      style={{ marginTop: "0.27in", marginBottom: "0in", lineHeight: "100%" }}
    >
      <br />
    </p>
  </div>
</div>;

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
        export default Terms;
