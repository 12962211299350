import React from 'react'

class Copyright extends React.Component{
  render(){
    return (
      <div className="copyright">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <p className="p-small">Copyright © 2020 <a href="https://www.venilabs.com">Venilabs Inc</a> - All rights reserved</p>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default Copyright;
