import React from 'react'
import InsuranceCheckoutPage from '../Components/Checkout/InsuranceCheckoutPage'

class InsuranceCheckout extends React.Component{
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render(){
    return (
      <div>
      <InsuranceCheckoutPage />
      </div>
    )
  }
}

export default InsuranceCheckout;
