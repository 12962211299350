import React from 'react'
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function generateJotFormLink(params) {
    let jotformLink = "https://form.jotform.com/202456662257155"
      jotformLink += "?"
      Object.keys(params).map((key) =>
        jotformLink += '&' + key + '='+ params[key]
    )
    console.log(jotformLink)
    return jotformLink
}

function PatientRegistration(props){
    return (
      <div>
        <div className="basic-3">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <h1><span className="turquoise">Checkout</span></h1>
                      <hr/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                    <iframe
                      id="PatientRegistration"
                      title="PatientRegistration"
                      allowtransparency="true"
                      allowFullScreen={true}
                      allow="geolocation; microphone; camera"
                      src={generateJotFormLink(queryString.parse(useLocation()['search']))}
                      frameBorder="0"
                      style={{height:'2700px', width:'100%'}}
                      scrolling="no"
                    >
                    </iframe>
                  </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
export default PatientRegistration;
