import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import HomePage from '../Containers/HomePage'
import Checkout from '../Containers/Checkout';
import InsuranceCheckout from '../Containers/InsuranceCheckout';

import TermsConditions from '../Containers/TermsConditions'
import PatientRegistration from '../Containers/PatientRegistration'
import PatientThankYou from '../Containers/PatientThankYou'
import PrivacyPolicy from '../Containers/PrivacyPolicy'
import GearyMd from '../Containers/GearyMd'
import Concacaf from '../Containers/Concacaf'
import Volvo from '../Containers/Volvo'


const Routes = (props) => {
  const location=useLocation()
  return(
    <Switch>
      <Route exact path='/' component={ HomePage } />

      <Route path='/Checkout' component={ Checkout } />
      <Route path='/InsuranceCheckout' component={ InsuranceCheckout } />
      
      <Route path='/patientCheckout'><Redirect to={{ pathname: '/checkout', search: location.search }} /> </Route>
      <Route path='/patientCheckout.html'><Redirect to={{ pathname: '/checkout', search: location.search }} /> </Route>

      <Route path='/PatientRegistration' component={ PatientRegistration } />
      <Route path='/PatientRegistration.html'><Redirect to={{ pathname: '/PatientRegistration', search: location.search }} /> </Route>

      <Route path='/PatientThankYou' component={ PatientThankYou } />
      <Route path='/PatientThankYou.html' ><Redirect to={{ pathname: '/PatientThankYou', search: location.search }} /> </Route>

      <Route path='/TermsConditions' component={ TermsConditions } />
      <Route path='/terms-conditions.html'><Redirect to="/TermsConditions" /> </Route>

      <Route path='/PrivacyPolicy' component={ PrivacyPolicy } />
      <Route path='/privacy-policy.html'><Redirect direct to="/PrivacyPolicy" /> </Route>

      {/*Customer*/}
      <Route path='/gearymd' component={ GearyMd } />

      {/*Sales Partners*/}
      <Route path='/Oscar'><Redirect to={{ pathname: '/Checkout', search: '?leadsource=Oscar' }} /> </Route>
      <Route path='/Johanna'><Redirect to={{ pathname: '/Checkout', search: '?leadsource=Johanna' }} /> </Route>
      <Route path='/Fervani'><Redirect to={{ pathname: '/Checkout', search: '?leadsource=Fervani' }} /> </Route>

      {/* Concacaf */}
      <Route path='/concacaf' component={ Concacaf } />
      {/* <Route path='/concacaf'><Redirect to={{ pathname: '/'}} /> </Route> */}

      {/* Volvo */}
      <Route path='/volvo' component={ Volvo } />

      <Route path='/' component={ HomePage } />

    </Switch>
  )
}

export default Routes;
