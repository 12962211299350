import React from "react";

class Videos extends React.Component {
    render() {
        return (
            <div>
                <div className="basic-1">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-lg-12">
                                <hr />
                                <h2>Covid-19 types of tests</h2>
                                <p className="p-heading p-large">
                                    We provide 2 types of tests
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h2>Covid-19 PCR Testing</h2>
                                    <p>
                                        The COVID-19 PCR test is a real-time
                                        reverse transcription polymerase chain
                                        reaction (RT-PCR) test for the
                                        qualitative detection of nucleic acid
                                        from SARS-CoV-2 in upper and lower
                                        respiratory specimens (such as
                                        nasopharyngeal or oropharyngeal swabs)
                                    </p>
                                    <a
                                        className="btn-solid-reg"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.cdc.gov/coronavirus/2019-ncov/lab/testing.html"
                                    >
                                        Learn move about Covid-19 PCR
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <div className="basic-3">
                                        <div className="video-wrapper">
                                            <a
                                                className="popup-youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.youtube.com/watch?v=Vd38iS_W7ww"
                                                data-effect="fadeIn"
                                            >
                                                <img
                                                    className="img-fluid"
                                                    src="images/covidPic.jpg"
                                                    alt="alternative"
                                                />
                                                <span className="video-play-button">
                                                    <span></span>
                                                </span>
                                            </a>
                                            <p className="justify-center">
                                                <strong>
                                                    Click on the video above
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="basic-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="image-container">
                                    <div className="basic-3">
                                        <div className="video-wrapper">
                                            <a
                                                className="popup-youtube"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.youtube.com/watch?v=NOK6drNPNck&t"
                                                data-effect="fadeIn"
                                            >
                                                <img
                                                    className="img-fluid"
                                                    src="images/COVIDAntibodyTest.jpg"
                                                    alt="alternative"
                                                />
                                                <span className="video-play-button">
                                                    <span></span>
                                                </span>
                                            </a>
                                            <p className="justify-center">
                                                <strong>
                                                    Click on the video above
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h2>Covid-19 Antibody Analysis</h2>
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="media">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                Finds if your immune system has
                                                the antibodies to fight
                                                infection
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                Antibodies DOES NOT detect the
                                                presence of Covid-19 virus
                                                (Antibody test can be negative
                                                even in infected patients)
                                            </div>
                                        </li>
                                        <li className="media">
                                            <i className="material-icons">
                                                check_circle
                                            </i>
                                            <div className="media-body">
                                                Antibodies can take days or
                                                weeks to develop in the body
                                                following exposure to a
                                                SARS-CoV-2 (COVID-19)
                                            </div>
                                        </li>
                                        <li>
                                            <a
                                                className="btn-solid-reg text-center"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.cdc.gov/coronavirus/2019-ncov/testing/serology-overview.html"
                                            >
                                                Learn more about Antibody
                                                analysis for Covid-19
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Videos;
