import React from "react";
import { Link } from "react-router-dom";

function NavBar(props) {
    const NavBarList = [
        {
            name: "Home",
            link: "/#header",
        },
        {
            name: "Services",
            link: "/#services",
        },
        {
            name: "Pricing",
            link: "/#pricing",
        },
        {
            name: "Buy Now",
            link: "/checkout",
        },
        {
            name: "Contact",
            link: "/#contact",
        },
    ];

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
                {/*Text Logo - Use this if you don't have a graphic logo*/}
                {/*<a className="navbar-brand logo-text page-scroll" href="index.html">Evolo</a>*/}
                {/*Image Logo*/}
                <a className="navbar-brand logo-image" href="/">
                    <img
                        src={
                            process.env.PUBLIC_URL + "/images/VenilabsLogo.png"
                        }
                        alt="alternative"
                    />
                </a>

                {/*Mobile Menu Toggle Button*/}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-awesome fas fa-bars"></span>
                    <span className="navbar-toggler-awesome fas fa-times"></span>
                </button>
                {/*end of mobile menu toggle button*/}

                <div
                    className="collapse navbar-collapse"
                    id="navbarsExampleDefault"
                >
                    <ul className="navbar-nav ml-auto">
                        {NavBarList.map((item) =>
                            item.link[1] === "#" ? (
                                props.current === item.name ? (
                                    <li className="nav-item" key={item.name}>
                                        <a
                                            className="nav-link page-scroll"
                                            href={item.link}
                                        >
                                            {item.name}
                                            <span className="sr-only">
                                                (current)
                                            </span>
                                        </a>
                                    </li>
                                ) : (
                                    <li className="nav-item" key={item.name}>
                                        <a
                                            className="nav-link page-scroll"
                                            href={item.link}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                )
                            ) : props.current === item.name ? (
                                <li className="nav-item" key={item.name}>
                                    <Link
                                        to={item.link}
                                        className="nav-link page-scroll"
                                    >
                                        {item.name}
                                        <span className="sr-only">
                                            (current)
                                        </span>
                                    </Link>
                                </li>
                            ) : (
                                <li className="nav-item" key={item.name}>
                                    <Link
                                        to={item.link}
                                        className="nav-link page-scroll"
                                    >
                                        {item.name}
                                    </Link>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
