import React, { Suspense } from "react";
import Header from "../Components/HomePage/Header";
import Pricing from "../Components/HomePage/Pricing";
import Services from "../Components/HomePage/Services";
import Videos from "../Components/HomePage/Videos";
import Contact from "../Components/HomePage/Contact";
import Testimonials from "../Components/HomePage/Testimonials";
import Team from "../Components/HomePage/Team";
import ServiceArea from "../Components/HomePage/ServiceArea";
import Footer from "../Components/HomePage/Footer";
import NavBar2 from "../Components/Common/Navbar2";

class HomePage extends React.Component {
    render() {
        return (
            <div>
                <NavBar2 />
                <Header />
                <Pricing />
                <ServiceArea />
                <Services />
                <Videos />
                <Suspense fallback={<div>Loading....</div>}>
                    <Contact />
                </Suspense>
                <Testimonials />
                <Team />

                <Footer />
            </div>
        );
    }
}

export default HomePage;
