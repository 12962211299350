import React from 'react'

function Slide(props){

      return (
          <div className="swiper-slide" key={props.slidenumber}>
              <div className="card">
                  <img className="card-image" src={props.testimonials[props.slidenumber].image} alt="alternative"/>
                  <div className="card-body">
                      <p className="testimonial-text">{props.testimonials[props.slidenumber].text}</p>
                      <p className="testimonial-author">{props.testimonials[props.slidenumber].author}</p>
                  </div>
              </div>
          </div>
      )
  }

export default Slide;
