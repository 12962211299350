import React from 'react';

const teamMembers = [
  { name: "Luisa",
    title: "Clinical Director",
    image: "images/team-member-1.svg"
  },
  { name: "Jorge",
    title: "IT Director",
    image: "images/team-member-2.svg"
  },
  { name: "Eduardo",
    title: "Developer and Operations Director",
    image: "images/team-member-2.svg"
  },
  { name: "Mayanin",
    title: "Finance",
    image: "images/team-member-4.svg"
  },
  { name: "Alejandra",
    title: "Accounting",
    image: "images/team-member-4.svg"
  },
  { name: "Braulio",
    title: "Customer Representative",
    image: "images/team-member-2.svg"
  },
  { name: "Joyce",
  title: "Customer Representative",
  image: "images/team-member-3.svg"
  },
  { name: "Carla",
    title: "Technician @ FL",
    image: "images/team-member-4.svg"
  },
  { name: "Cessi",
    title: "Technician @ FL",
    image: "images/team-member-4.svg"
  },
  { name: "Tay",
    title: "Technician @ Houston-TX",
    image: "images/team-member-2.svg"
  },
  { name: "Filip",
    title: "Technician @ NYC",
    image: "images/team-member-2.svg"
  },
  { name: "Emmanuel",
  title: "Technician @ DC Metro Area",
  image: "images/team-member-2.svg"
  },
  { name: "Bryant",
  title: "Technician @ Richmond-VA",
  image: "images/team-member-2.svg"
  },
  { name: "Marchelle",
    title: "Technician @ Richmond-VA",
    image: "images/team-member-4.svg"
  },
  { name: "Yolanda",
  title: "Technician @ Tampa-FL",
  image: "images/team-member-4.svg"
  },
  { name: "Michelle",
  title: "Technician @ Madison-WI",
  image: "images/team-member-4.svg"
  },
  { name: "Yolanda",
  title: "Technician @ Houston-TX",
  image: "images/team-member-4.svg"
  },
  { name: "Meagan",
  title: "Technician @ San Francisco-CA",
  image: "images/team-member-4.svg"
  },
  { name: "Kiandra",
  title: "P.A @ Broward-FL",
  image: "images/team-member-4.svg"
  },
  { name: "Kiara",
  title: "Technician @ NYC",
  image: "images/team-member-4.svg"
  }, 
  { name: "Yareed",
  title: "Technician @ Broward-FL",
  image: "images/team-member-4.svg"
  },  

  
]
const teamList = teamMembers.map((member,index)=>
    <div className="team-member" key={index}>
        <div className="image-wrapper">
            <a href="/#contact"><img className="img-fluid" src={member.image} alt="alternative"/></a>
        </div>
        <p className="p-large"><strong>{member.name}</strong></p>
        <p className="job-title">{member.title}</p>
    </div>
)

class Team extends React.Component{
  render(){
    return (
            <div id="Team" className="basic-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Meet The Team</h2>
                            <p className="p-heading p-large">Our second family</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                          {teamList}

                        </div>
                    </div>
                </div>
            </div>
    )
  }
}
export default Team;
