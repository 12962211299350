import React from 'react'
import ServiceCard from './Services/Card'

const serviceCardData = [
    {
      cardTitle: "1. Order Test Online",
      cardParagraph: "Our team is waiting for your order, we will contact you to confirm your appointment",
      cardIcon: "images/services-icon-1.svg"
    },
    {
      cardTitle: "2. We collect samples",
      cardParagraph: "We will come to you and get samples needed and take it to the laboratory",
      cardIcon: "images/services-icon-2.svg"
    },
    {
      cardTitle: "3. Get results",
      cardParagraph: "We send your results directly to you",
      cardIcon: "images/services-icon-3.svg"
    }
]

class Services extends React.Component{
  render(){
    return (
      <div id="services" className="cards-1">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <hr/>
                      <h2>Onsite Covid-19 testing Services</h2>
                      <p className="p-heading p-large">We serve small and medium sized companies in all industries with high quality services. We make covid-19 testing process simple with these 3 simple steps presented below</p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                      <ServiceCard cardInfo={serviceCardData[0]} />
                      <ServiceCard cardInfo={serviceCardData[1]} />
                      <ServiceCard cardInfo={serviceCardData[2]} />
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default Services;
