import React from "react";

class Header extends React.Component {
    render() {
        return (
            <div>
                <header id="header" className="header">
                    <div className="header-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="text-container">
                                        <h1>
                                            <span className="turquoise">
                                                Veni
                                            </span>
                                            labs We come to you!
                                        </h1>
                                        <p className="p-large">
                                            Covid-19 testing simplified. Get
                                            tested from the comfort of your
                                            home or office
                                        </p>
                                        <a
                                            className="btn-solid-lg page-scroll"
                                            href="#services"
                                        >
                                            DISCOVER
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="image-container">
                                        <img
                                            className="img-fluid"
                                            src="../images/header-teamwork.svg"
                                            alt="alternative"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
export default Header;
