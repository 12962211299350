import React from 'react';
// import 'Venilabs-New-Front-end/venilabs-new-front-end/public/css/styles.css';

import { Helmet } from 'react-helmet';
import Routes from './config/routes';
import Copyright from './Components/Common/Copyright';

class App extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <link rel="icon" href="images/VeniFavicon.gif"/>
          <meta charset="utf-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

          <meta name="title" content="Venilabs - Covid 19 Testing simplified"/>
          <meta name="description" content="Venilabs - Convenient in-home or office lab services. We can provide PCR certificate for travelers"/>
          <meta name="author" content="Venilabs"/>

          <meta property="og:site_name" content="Venilabs" />
          <meta property="og:site" content="https://www.venilabs.com" />
          <meta property="og:title" content="Venilabs - Covid 19 Testing simplified"/>
          <meta property="og:description" content="Venilabs - Convenient in-home or office lab services. We can provide PCR certificate for travelers" />
          <meta property="og:image" content="" />
          <meta property="og:url" content="" />
          <meta property="og:type" content="website" />
        </Helmet>
          <Routes />
        <Copyright />
      </div>
    )
  }
}

export default App;
