import React from 'react'

class ThankYou extends React.Component{
  render(){
    return (
        <div className="basic-3">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12 text-center">
                      <p> Your order has been placed, You will receive an email with your order information.</p>
                      <p> Please check your email for a link to add more patients to your order.</p>
                        <br/>
                        <br/>
                      <h4>What Happens now?</h4>
                      <hr/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                      <lu>
                          <li>
                              The day prior to your appointment, we will contact you  via phone or email to confirm your appointment.
                          </li>
                          <li>
                              One of our technicians will come to the address provided at the time of your appointment. They will have your phone information and will contact you directly, if they are running late or they can't find the address.
                          </li>
                          <li>
                              Most swaps are done via the mouth, because its more comfortable for the patient. However, if you require a nose swab, please let us know so
                          </li>
                          <li>
                              For your security and the security of our staff, the healthcare professional will have protective gear: mask, coat, gloves, and facemask.
                          </li>
                          <li>
                              Please designate an area well ventilated of your Home/Office for the collection.
                          </li>
                      </lu>
                      <hr/>
                      <p>After the collection of the sample, we will take it to the labs and process it. We will notify you of the result via the email provided when registering </p>
                      <p>Depending of the service requested, our target is to provide results in less than 72 hours and less than 48 hours for expedite service.</p>
                      <p>If you have any questions please feel free to contact us at <a href= "mailto:info@venilabs.com"> info@venilabs.com </a></p>
                  </div>
              </div>
          </div>
        </div>
    )
  }
}
export default ThankYou;
