import React from 'react'
import { Helmet } from 'react-helmet';
import loadable from "@loadable/component";

const LoadableJotFormFrame = loadable (() => import("./InsuranceJotFormFrame"));
  
function InsuranceCheckoutPage(props){
    return (
      <div>
        <Helmet>
          <meta name="title" content="Venilabs - Insurnace Checkout"/>
          <meta name="description" content="Venilabs - Easiest way to order Covid 19 Testing"/>
        </Helmet>
        <div className="basic-3">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <h1><span className="turquoise">Checkout</span></h1>
                      <hr/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                    <LoadableJotFormFrame fallback={<div>Loading...</div>}/>
                  </div>
              </div>
          </div>
        </div>
      </div>
    )
}
export default InsuranceCheckoutPage;
