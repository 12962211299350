import React from "react";

function PricingCard(props) {
    const listItems = props.cardInfo.cardBullets.map((bullet, index) => (
        <li className="media" key={index}>
            <i className="material-icons">check_circle</i>
            <div className="media-body">{bullet}</div>
        </li>
    ));

    return (
        <div className="card">
            {props.cardInfo.cardLabel ? (
                <div className="label">
                    <p className="best-value">{props.cardInfo.cardLabel}</p>
                </div>
            ) : (
                ""
            )}
            <div className="card-body">
                <div className="card-title">{props.cardInfo.cardTitle}</div>
                <div className="card-subtitle">
                    {props.cardInfo.cardSubtitle}
                </div>
                <hr className="cell-divide-hr" />
                <div className="price">
                    <span className="currency">$</span>
                    <span className="value">{props.cardInfo.cardValue}</span>
                    <div className="frequency">payment</div>
                </div>
                <hr className="cell-divide-hr" />
                <ul className="list-unstyled li-space-lg">{listItems}</ul>
                <div className="button-wrapper">
                    <a className="btn-solid-reg page-scroll" href={props.cardInfo.cardLink}>
                        {props.cardInfo.cardButton ? props.cardInfo.cardButton : 'Buy Now' } 
                    </a>
                </div>
            </div>
        </div>
    );
}
export default PricingCard;
