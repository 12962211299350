import React from 'react'
import CheckoutPage from '../Components/Partners/Volvo/CheckoutPage'


class VolvoCheckout extends React.Component{
  componentDidMount() {
    window.scrollTo(0, 0)

  }
  render(){
    return (
      <div>
      <CheckoutPage />
      </div>
    )
  }
}

export default VolvoCheckout;