import React from 'react'
import CheckoutPage from '../Components/Checkout/CheckoutPage'

class Checkout extends React.Component{
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render(){
    return (
      <div>
      <CheckoutPage />
      </div>
    )
  }
}

export default Checkout;
