import React from 'react'

function ServiceCard(props){
  return (
    <div className="card">
        <img className="card-image" src={props.cardInfo.cardIcon} alt="alternative"/>
        <div className="card-body">
            <h4 className="card-title">{props.cardInfo.cardTitle}</h4>
            <p>{props.cardInfo.cardParagraph}</p>
        </div>
    </div>
  )
}
export default ServiceCard;
