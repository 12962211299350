import React from 'react'

class GearyMd extends React.Component{
  render(){
    return (
      <div>
        <div className="basic-3">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <h1><span className="turquoise">Geary MD</span></h1>
                      <hr/>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-12">
                    <div className="container">
                      <iframe
                        id="GearyMD form"
                        title="GearyMD"
                        allowtransparency="true"
                        allowFullScreen={true}
                        allow="geolocation; microphone; camera"
                        src="https://form.jotform.com/202715445440146"
                        frameBorder="0"
                        style={{height:'2100px', width:'100%'}}
                        scrolling="no"
                      >
                      </iframe>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GearyMd;
